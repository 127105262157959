import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class AplicacionesManualesService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()   
  
  public GetCNsList(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getCNsList' , { headers: headers } )
      .map(res => res.json())
  }

  public GetFacRecPendientes(sucursal, date1, date2){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getFacRecPendientes/'+sucursal+"/"+date1+"/"+date2 , { headers: headers } )
      .map(res => res.json())
  }

  public AplicarFactura(num_recibo, num_factura, monto, org_id){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/aplicarFactura/'+num_recibo+"/"+num_factura+"/"+monto+"/"+org_id , { headers: headers } )
      .map(res => res.json())
  }


}