import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';


@Injectable()
export class UsersService {

  constructor(
    private http: Http
  ) { }

  getUserInfo(email) {
    return this.http.get('/api/user/' + email)
      .map(res => res.json())
  }

  getCnInfo(salesrep_id) {
    return this.http.get('/api/cn/' + salesrep_id)
      .map(res => res.json())
  }
}
