import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class RespaldoDiaService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()
  
  public GetDiaRespaldo(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getDiasRespaldo', { headers: headers } )
      .map(res => res.json())
  }
}
