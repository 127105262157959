import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class ActividadesService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()   
  
  public getActividades(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getActividades' , { headers: headers } )
      .map(res => res.json())
  }

  public SaveActividades(arrayActividades){
    return this.http.post('/api/saveActividades',{ arrayActividades: arrayActividades})
      .map(res => res.json())
  }

  public SaveActividadesRecibos(arrayActividades){
    return this.http.post('/api/saveActividadesRecibos',{ arrayActividades: arrayActividades})
      .map(res => res.json())
  }
  
  public aplicarActividadesFac(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/aplicarFacturas' , { headers: headers } )
      .map(res => res.json())
  }
  
  public aplicarActividadesRec(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/aplicarRecibos' , { headers: headers } )
      .map(res => res.json())
  }
}