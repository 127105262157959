import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class CentrosnegocioService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  private token = this.authService.getToken()

  public putCentroNegocio(centro_negocio) {
    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.put('/api/centro_negocio', { centro_negocio: centro_negocio }, { headers: headers })
      .map(res => res.json())
  }

  public getOrganizaciones() {
    const headers = new Headers();
    headers.append('Authorization', this.token);

    return this.http.get('/api/organizaciones', { headers: headers })
      .map(res => res.json());
  }

  public getCentrosNegocio() {
    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.get('/api/centros_negocio', { headers: headers })
      .map(res => res.json())
  }

  public postCentroNegocio(centro_negocio) {
    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.post('/api/centros_negocio', { centro_negocio: centro_negocio }, { headers: headers })
      .map(res => res.json())
  }

}
