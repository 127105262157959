import { Component, OnInit } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker'
import * as moment from 'moment'
import { MovimientosSABService } from '../services/movimientos-sab.service';
import {global_functions} from '../global_functions/global_functions';
import { IMyDrpOptions, IMyDateRangeModel } from 'mydaterangepicker';




@Component({
  selector: 'app-movimientos-sab',
  templateUrl: './movimientos-sab.component.html',
  styleUrls: ['./movimientos-sab.component.css']
})
export class MovimientosSabComponent implements OnInit {

  private loader: boolean = true

  private eventStart:any
  private eventEnd:any
  private cuentasArray = []
  private orgArray = []  
  private movSabArray = [] 
  private dataArray = [] 


  private estatusValue: string 
  private cuentaValue: string 
  private recibosValue: string 
  private fechaValue: string 
  private orgValue: string 


  private isSaving = false
  private moduleMovSABPermission = false
  private globalObj = global_functions;

  private estatusArray =[{"Name": "SIN_AUTORIZAR"},{"Name": "AUTORIZADO"},{"Name": "ANULADO_MANUAL"}]  

  private recibosArray=[{"Name": "GENERADOS"},{"Name": "NO_GENERADOS"}]  

  constructor(private MovimientosSABService: MovimientosSABService) { }

  ngOnInit() {
    this.moduleMovSABPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_mov_sab'))

    if(this.moduleMovSABPermission){
      this.GetCuentasBancarias()
    }else{
      this.globalObj.alertAccesDenied()
    }
  }

  GetCuentasBancarias(){
    this.MovimientosSABService.GetCuentasBancarias().subscribe(data => {
      this.dataArray = data
      var val1 = ''
      var val2 = ''
      for (let index = 0; index < this.dataArray.length; index++) {
        val1 = data[index].UNIDAD[0];
        if(val1!=val2){
          this.orgArray.push(val1)
          val2 = val1
        }
      }

      this.estatusValue = this.estatusArray[0].Name
      this.recibosValue = this.recibosArray[0].Name
      this.orgValue = this.orgArray[0]
      this.fechaValue = moment().format('DD/MM/YYYY')

      this.fillCuentasArray(this.orgValue)

      this.loader = false

      console.log("GetCuentasBancarias -> correct")
    }, err => {
      console.log("GetCuentasBancarias -> Error:  "+err)
      this.cuentasArray = []
      this.loader = false
    })

   
  }

  private fillCuentasArray(currentOrg){
    this.cuentasArray = []
    for (let index = 0; index < this.dataArray.length; index++) {
      let  element = this.dataArray[index];
      if(element.UNIDAD == currentOrg){
        this.cuentasArray.push(element.CUENTA);
      }
    }
    this.cuentaValue = this.cuentasArray[0]
  }


  private myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
  };

  private model: any = {beginDate: '',
                        endDate: ''};

  /**
   * It´s called when a date is selected
   * @param event 
   */
  onDateRangeChanged(event: IMyDateRangeModel) {
    // event properties are: event.beginDate, event.endDate, event.formatted,
    // event.beginEpoc and event.endEpoc
    
    this.eventStart = this.completeDate(event.beginDate.day) +"/"+ this.completeDate( event.beginDate.month)+"/"+ event.beginDate.year

    this.eventEnd = this.completeDate(event.endDate.day) +"/"+ this.completeDate(event.endDate.month)+"/"+ event.endDate.year
  }

  /**
   * 
   * @param day Return 2 digits 
   */
  private completeDate(day){
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
          break;
      case 2:
      endDay = '02'
          break;
      case 3:
      endDay = '03'
          break;
      case 4:
      endDay = '04'
          break;
      case 5:
      endDay = '05'
          break;
      case 6:
      endDay = '06'
          break;
      case 7:
      endDay = '07'
          break;
      case 8:
      endDay = '08'
          break;
      case 9:
      endDay = '09'
          break;
      default:
      endDay=""+day
    }
    return endDay
  }

  private buscar(){
    this.isSaving = true
    this.MovimientosSABService.GetMovimientosSAB(this.estatusValue,btoa(this.cuentaValue),btoa(this.eventStart),btoa(this.eventEnd),this.recibosValue).subscribe(data => {
      this.movSabArray = data
      this.isSaving = false
      console.log("GetMovimientosSAB -> correct")
    }, err => {
      console.log("GetMovimientosSAB -> Error:  "+err)
      this.movSabArray = []
      this.isSaving = false
    })
  }

  private estatusChange(value){
      this.estatusValue = value
  }
  
  private cuentasChange(value){
    this.cuentaValue = value
  }

  private recibosChange(value){
    this.recibosValue = value
  }

  private orgChange(value){
    this.orgValue = value
    this.fillCuentasArray(this.orgValue)
  }

  private downloadData(){
    this.globalObj.prepareDownload("Movimientos SAB",this.movSabArray)
  }

}  

