import { Component, OnInit } from '@angular/core';
import { global_functions } from '../global_functions/global_functions';
import { CantiaColaboradorService } from '../services/cantia-colaborador.service';
import { IMyDateRangeModel, IMyDrpOptions } from 'mydaterangepicker';
import * as moment from 'moment'

@Component({
  selector: 'app-cantia-colaborador',
  templateUrl: './cantia-colaborador.component.html',
  styleUrls: ['./cantia-colaborador.component.css']
})
export class CantiaColaboradorComponent implements OnInit {

  private loader: boolean = true
  private showDetail: boolean = false
  private moduleVentasCantiaPermission = false
  private totalif: number = 0;
  private totaldf: number = 0;
  private totalf: number = 0;
  private totalord: number = 0;
  private eventStart = ''
  private eventEnd = ''
  private ventas_colaborador_cantia : any
  private ventas_colaborador_cantia_filter: any
  public globalObj = global_functions;

  constructor( private sevice: CantiaColaboradorService) { }

  myDateRangePickerOptions: IMyDrpOptions = {

    dateFormat: 'dd/mm/yyyy',
    showSelectorArrow: true,
    monthSelector: true,
    yearSelector: true,
    
  };

  private fechai = moment().subtract(7, 'day').format('DD/MM/YYYY');
  private fechaf = moment().format('DD/MM/YYYY');
  private model: any = { beginDate: this.fechai, endDate: this.fechaf };


  ngOnInit() {
    this.moduleVentasCantiaPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_cantia'))
    if (this.moduleVentasCantiaPermission) {
      this.getInfoCantia(this.fechai, this.fechaf);
    } else {
      this.globalObj.alertAccesDenied()
    }
  }

  private getInfoCantia(fechai, fechaf) {
    
    this.loader = true
    this.sevice.GetVentasCantia(btoa(fechai), btoa(fechaf)).subscribe(data => {

      this.ventas_colaborador_cantia = data

      for (let i = 0; i < this.ventas_colaborador_cantia.length; i++) {
        const e = this.ventas_colaborador_cantia[i];
        this.ventas_colaborador_cantia[i].FORMA_DE_PAGO[0] = e.FORMA_DE_PAGO[0].replace(',','');
        this.ventas_colaborador_cantia[i].FECHA[0] = e.FECHA[0].substring(0, 10);
        this.ventas_colaborador_cantia[i].IMPORTE_TOTAL_POS[0] = e.IMPORTE_TOTAL_POS[0].replace(/,/g, '');

      }
        
      this.ventas_colaborador_cantia_filter = this.ventas_colaborador_cantia;
      this.loader = false
      }, err => {
        console.log("Error-> "+err)
        this.ventas_colaborador_cantia = []
        this.loader = false
    })
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    this.ventas_colaborador_cantia = [];
    this.eventStart = this.pad(event.beginDate.day) + "/" + this.pad(event.beginDate.month) + "/" + event.beginDate.year
    this.eventEnd = this.pad(event.endDate.day) + "/" + this.pad(event.endDate.month) + "/" + event.endDate.year
    this.getInfoCantia(this.eventStart, this.eventEnd)
  }

  private pad (d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  private downloadData() {
    this.globalObj.prepareDownload("Ventas de cantia credito colaborador", this.ventas_colaborador_cantia_filter)
  }

}
