
import { Component, OnInit } from '@angular/core';
import { CentrosnegocioService } from '../services/centrosnegocio.service';
import { global_functions } from '../global_functions/global_functions';


@Component({
  selector: 'app-centros-negocio',
  templateUrl: './centros-negocio.component.html',
  styleUrls: ['./centros-negocio.component.css']
})

export class CentrosNegocioComponent implements OnInit {

  private loader = true;
  private centros_negocio: any;
  private organizaciones: any;
  private create_cn = false;
  private update_cn = false;
  private globalObj = global_functions;

  private page_table_main = true;
  private page_form_cn = false;
  private page_button_showForm = true;
  private page_alert_cn_registrado_success = false;
  private page_alert_cn_registrado_error = false;
  private page_alert_cn_actualizado_success = false;
  private moduleCnPermission = false;


  private centro_negocio: any = {
    nombre: '',
    sucursal_facturacion: '',
    serie_facturacion: '',
    organizacion: '',
    referencia_efectivos: '',
    fecha_cierre: '',
    terminales: ''
  };

  constructor(
    private centrosNegocioService: CentrosnegocioService
  ) { }

  ngOnInit() {

    this.moduleCnPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_cn'))

    if (this.moduleCnPermission) {
      this.centrosNegocioService.getCentrosNegocio().subscribe(data => {
        this.centros_negocio = data;
        this.centros_negocio.forEach(element => {
          element.FECHA_APERTURA[0] = element.FECHA_APERTURA[0].substr(0, 10)
          element.FECHA_CIERRE[0] = element.FECHA_CIERRE[0].substr(0, 10)
        })
        this.loader = false
      }, err => {
        console.log(err)
        this.loader = false
      })
    } else {
      this.globalObj.alertAccesDenied()
    }
  }

  private _create_cn() {
    this.create_cn = true
    this.update_cn = false
  }

  private _update_cn() {
    this.update_cn = true
    this.create_cn = false
  }

  private UpdateCn(centro_negocio) {
    if (this.validaCnForm()) {
      this.centrosNegocioService.putCentroNegocio(centro_negocio).subscribe(data => {
        if (data) {
          this.page_alert_cn_actualizado_success = true
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          this.page_alert_cn_registrado_error = true
        }
      })
    }
  }

  private loadCn(obj) {
    this.showCnForm()
    let index = this.centros_negocio.findIndex(x => x.NOMBRE == obj.NOMBRE && x.TERMINALES == obj.TERMINALES); //get item index in array
    this.centro_negocio.nombre = this.centros_negocio[index].NOMBRE[0]
    this.centro_negocio.sucursal_facturacion = this.centros_negocio[index].SUCURSAL_FACTURACION[0]
    this.centro_negocio.serie_facturacion = this.centros_negocio[index].SERIE_FACTURACION[0]
    this.centro_negocio.organizacion = this.centros_negocio[index].ORGANIZACION[0]
    this.centro_negocio.referencia_efectivos = this.centros_negocio[index].REFERENCIA_EFECTIVOS[0]
    this.centro_negocio.fecha_cierre = this.centros_negocio[index].FECHA_CIERRE[0]
    this.centro_negocio.terminales = this.centros_negocio[index].TERMINALES[0]
    this.centro_negocio.readonly = true
  }

  private InsertCN() {
    if (this.validaCnForm()) {
      this.centrosNegocioService.postCentroNegocio(this.centro_negocio).subscribe(data => {
        if (data) {
          console.log(data)
          this.page_alert_cn_registrado_success = true
          this.page_alert_cn_registrado_error = false
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          this.page_alert_cn_registrado_error = true
          this.page_alert_cn_registrado_success = false

        }
      })
    }
  }

  private validaCnForm() {
    if (this.centro_negocio.nombre.trim() != "" && this.centro_negocio.sucursal_facturacion.trim() != "" && this.centro_negocio.serie_facturacion.trim() != "" && this.centro_negocio.organizacion.trim() != "" && this.centro_negocio.referencia_efectivos.trim() != "" /*&& this.centro_negocio.terminales.trim() != ""*/) {
      if (isNaN(this.centro_negocio.organizacion.trim())) {
        alert("Organizacion debe ser numérico.")
        return false
      } else {
        return true
      }
    } else {
      alert("¡Los campos no pueden estar vacíos!")
      return false
    }
  }

  private showCnForm() {
    this.centro_negocio = {
      nombre: "",
      sucursal_facturacion: "",
      serie_facturacion: "",
      organizacion: "",
      referencia_efectivos: "",
      fecha_cierre: "",
      terminales: ""
    }

    this.centrosNegocioService.getOrganizaciones().subscribe(data => {
      this.organizaciones = data;
    });

    this.page_table_main = false
    this.page_form_cn = true
    this.page_button_showForm = false
  }

  private hideCnForm() {
    this.page_table_main = true
    this.page_form_cn = false
    this.page_button_showForm = true
  }

  private downloadData() {
    this.globalObj.prepareDownload("Centros de Negocio", this.centros_negocio)
  }

}
