import { Component, OnInit } from '@angular/core';
import { BancoVianneyService } from '../services/banco_vianney.service';
import { global_functions } from '../global_functions/global_functions';


@Component({
  selector: 'app-banco-vianney',
  templateUrl: './banco-vianney.component.html',
  styleUrls: ['./banco-vianney.component.css']
})
export class BancoVianneyComponent implements OnInit {

  private banco_vianney: any;
  private banco_vianney_length = 0;
  private loader = true;
  private refTemp = '';
  private moduleBancoPermission = false;
  private globalObj = global_functions;
  private referenciasArray = [];


  constructor(
    private BancoVianneyService: BancoVianneyService
  ) { }

  ngOnInit() {
    this.moduleBancoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_banco'));

    if (this.moduleBancoPermission) {
      
      this.BancoVianneyService.getReporteReferencias().subscribe(data => {
        this.referenciasArray = data;
        this.loader = false;
      }, err => {
        console.log('Error-> ' + err);
        this.loader = false;
      })
    } else {
      this.globalObj.alertAccesDenied();
    }
  }

  private getInitialInfo(referencia) {
    this.refTemp = referencia
    this.loader = true
    this.banco_vianney = null
    this.banco_vianney_length = 0
    this.BancoVianneyService.getBancoVianney(referencia).subscribe(data => {
      this.banco_vianney = data
      this.banco_vianney_length = this.banco_vianney.length
      this.loader = false
    }, err => {
      console.log("Error-> " + err)
      this.loader = false
    })
  }

  private downloadData() {
    if (this.banco_vianney.length > 0) {
      var data, filename, link;

      var stockData = [];

      this.banco_vianney.forEach(function (element) {
        stockData.push(element)
      });

      var csv = this.convertArrayOfObjectsToCSV({
        data: stockData
      });
      if (csv == null) return;

      filename = "Referencia_" + this.refTemp + '.csv';

      if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
      }
      data = encodeURI(csv);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      link.click();
    }
  }

  private downloadReferenciasData() {
    if (this.referenciasArray.length > 0) {
      var data, filename, link;

      var stockData = [];

      this.referenciasArray.forEach(function (element) {
        stockData.push(element)
      });

      var csv = this.convertArrayOfObjectsToCSV({
        data: stockData
      });
      if (csv == null) return;

      filename = "Reporte de Referencias.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
      }
      data = encodeURI(csv);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      link.click();
    }
  }

  private convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }




}
