import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/Rx';

@Injectable()
export class AuthService {

  profile: any

  constructor(
    private http: Http
  ) { }

  public getToken(){
    // console.log(JSON.parse(localStorage.getItem('currentUser')))
    return JSON.parse(localStorage.getItem('currentUser')).token
  }

  public getUserInfo(){
    return JSON.parse(localStorage.getItem('currentUser'))
  }

  public authentication(mail, username, imageUrl){
    let user = {
      username: username
    }

    return this.http.post('/api/login', { user: user })
    .map(res => {
      let user = res.json();
      if (user.token) {
          localStorage.setItem('currentUser', JSON.stringify({loggedIn: true, username: username, mail: mail, imageUrl: imageUrl, token: "JWT " + user.token, date: new Date() }))
      } 
      else { 
        user = false
      }
      return user;
    }) 
  }
  
  public login(username){
    let user = {
      username: username
    }

    return this.http.post('/api/login', { user: user })
      .map(res => res.json())
  }

  public userPermisions(user){
    return this.http.post('/api/userPermisions',{user: user})
      .map(res => res.json())
  }
}
