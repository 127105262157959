import { Component, OnInit } from '@angular/core';
import { SemaforoService } from '../services/semaforo.service';
import * as moment from 'moment';
import {global_functions} from '../global_functions/global_functions';


@Component({
  selector: 'app-semaforo',
  templateUrl: './semaforo.component.html',
  styleUrls: ['./semaforo.component.css']
})
export class SemaforoComponent implements OnInit {
  private globalObj = global_functions;
  private loader: boolean = true
  private facturas_sin_datos_de_cliente: any = 0
  private correccion_de_importes_a_detalle: any = 0
  private correccion_pagos_credi_adi: number = 0
  private casos_extra_transacciones: number = 0
  private fp_vales_data = []
  private fp_vales_length: number = 0
  private no_facturadas_vianney_data = []
  private no_facturadas_vianney_length: number = 0
  private no_facturadas_textilera_length:  number = 0
  private no_facturadas_textilera_data = []

  private moduleSemaforoPermission = false


  // private fecha_inicio = moment().subtract(5, 'days').format('L')
  private fecha_inicio = moment().subtract(3, 'days').format("DD/MM/YYYY")
  
  // private fecha_fin: string = moment().format('L')
  private fecha_fin: string = moment().subtract(1, 'days').format("DD/MM/YYYY")

  constructor(
    private semaforoService: SemaforoService
  ) { }

  ngOnInit() {

    this.moduleSemaforoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_semaforo'))

    if(this.moduleSemaforoPermission){

      this.semaforoService.getProblemas().subscribe(data => {

        this.facturas_sin_datos_de_cliente = parseInt(data.CASO1[0]) + parseInt(data.CASO2[0])
        this.correccion_de_importes_a_detalle = parseInt(data.CASO3[0])
        this.correccion_pagos_credi_adi = parseInt(data.CASO4[0])
        this.casos_extra_transacciones = parseInt(data.CASO5[0]) + parseInt(data.CASO6[0])
  
        if (this.facturas_sin_datos_de_cliente == 0)
          this.facturas_sin_datos_de_cliente = "Correcto"
  
        if (this.correccion_de_importes_a_detalle == 0)
          this.correccion_de_importes_a_detalle = "Correcto"
  
        if (this.correccion_pagos_credi_adi < 0)
          this.correccion_pagos_credi_adi = 0
  
        this.loader = false
      })
  
      this.semaforoService.getFP_Vales().subscribe(data => {
        this.fp_vales_data = data
        this.fp_vales_length= this.fp_vales_data.length
        this.loader = false
      }, err => {
        console.log("Error-> "+err)
        this.loader = false
      })
  
      let fecha_inicio_encode = btoa(this.fecha_inicio)
      let fecha_fin_encode = btoa(this.fecha_fin)
  
      this.semaforoService.getNoFacturadasVianney(fecha_inicio_encode, fecha_fin_encode).subscribe(data => {
        this.no_facturadas_vianney_data = data
        this.no_facturadas_vianney_length= this.no_facturadas_vianney_data.every.length
        this.loader = false
      }, err => {
        console.log("Error-> "+err)
        this.loader = false
      })
  
      this.semaforoService.getNoFacturadasTextilera(fecha_inicio_encode, fecha_fin_encode).subscribe(data => {
        this.no_facturadas_textilera_data = data
        this.no_facturadas_textilera_length = this.no_facturadas_textilera_data.every.length
        this.loader = false
      }, err => {
        console.log("Error-> "+err)
        this.loader = false
      })
    }else{
      this.globalObj.alertAccesDenied()
    }    
  }


  private downloadData(flag) {
    if(flag==1){
      if(this.no_facturadas_vianney_data.length>0){
          this.globalObj.prepareDownload("no_facturadas_vianney",this.no_facturadas_vianney_data)
      }
    }else if(flag==2){
      if(this.no_facturadas_textilera_data.length>0){
        this.globalObj.prepareDownload("no_facturadas_textilera",this.no_facturadas_textilera_data)
      }
    } else if(flag==3){
      if(this.fp_vales_data.length>0){
        this.globalObj.prepareDownload("fp_vales",this.fp_vales_data)
      }
    }    
  }

  
}
