import { Component, NgZone, OnInit } from "@angular/core";
import { AppContext } from "../../infrastructure/app.context";
import { FileInfo} from "../../models/fileInfo";
import {global_functions} from '../global_functions/global_functions';
import { AppRepository } from "../../infrastructure/repositories/app.repository";
import { IMyDateModel, IMyDpOptions } from "mydatepicker";
import { DatePipe } from '@angular/common'
import * as moment from 'moment'
import { AplicacionesManualesService } from '../services/aplicaciones_manuales.service';
import { OneDriveService } from '../services/one_drive.service';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { forEach } from "@angular/router/src/utils/collection";

const CLIENT_ID = "676687949327-2etrhv8acfn8knuono1co04o727kogqj.apps.googleusercontent.com";
const API_KEY = "AIzaSyASMTkc2kZ4pTghuQa-EHYBAjVAF-ubFNY";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
var SCOPES = 'https://www.googleapis.com/auth/drive';

@Component({
  selector: 'app-one-drive',
  templateUrl: './one-drive.component.html',
  styleUrls: ['./one-drive.component.css']
})
export class OneDriveComponent implements OnInit {
  private moduleOneDrivePermission: boolean = false
  private isLoggedIn: boolean = false
  private showChart: boolean = false
  private loader: boolean = false
  private files: FileInfo[] = [];
  private globalObj = global_functions;
  private filesArray = []
  private arrayTiendasConVentas = []
  private arrayTiendasSinVentas = []
  private foldersArray = [] 
  private foldersCNArray = []
  private foldersFechasArray = [] 
  private filesFechasCNArray = []
  private arrayCns = []
  private arrayCnsMissing = []
  private arrayCnsCorrect = []
  private loopIndex = 0
  private botonChecked:string = 'Seleccionar Todo';

  private googleAuth: gapi.auth2.GoogleAuth;
  private globalIndex = 0
  private dateToSearch = ''
  private dateSelected = ''
  private sendEmailArray = []; 
  
  public barChartLabels:string[] = [];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;
 
  public barChartData:any[] = [
    {data: [], label: 'Pendientes'},
    {data: [], label: 'En Drive'}
  ];


  constructor(
      private appContext: AppContext,
      private zone: NgZone,
      private appRepository: AppRepository,
      public datepipe: DatePipe,
      private AplicacionesManualesService: AplicacionesManualesService,
      private oneDriveService: OneDriveService, 
      private authService:AuthService) {}

  private myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: true
  }

  
  private fecha: any = {
    formatted: moment().format('DD/MM/YYYY')
  }

  ngOnInit(): void {
    this.moduleOneDrivePermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_one_drive')) //get permission from local storage
    if(this.moduleOneDrivePermission){
      this.AplicacionesManualesService.GetCNsList().subscribe(data => { //call getCNsList in aplicaciones_manuales.js
        this.arrayCns = data;
        // this.arrayCnsOriginal.forEach(cn => {
        //   if (cn.ACCOUNT_NUMBER == 'CN_VIA' || cn.ACCOUNT_NUMBER == 'CN_TEX') {
        //     this.arrayCns.push(cn);
        //   }
        // });
        this.initClient(true)  
        // console.log("GetCNsList correct->"+this.arrayCns.length+" total")
      }, err => {
        // console.log("GetCNsList -> Error:  "+err)
        this.loader = false
        window.location.reload();        //reload page when there was an error
      })
    }else{
      this.globalObj.alertAccesDenied()
    }  
  }
  
  /**
   * It´s called when a date is selected
   * @param event 
   */
  private onDateChanged(event: IMyDateModel) {
    // console.log(event)
    // console.log(event.date)
    // console.log(event.formatted)
    
    if(this.isSignedIn()){ //if session is active
      this.loopIndex = 0
      this.globalIndex = 0
      this.loader = true
      // Swal({title:'El proceso puede tomar varios minutos...',text: `${this.loopIndex} folders revisados de ${this.foldersCNArray.length} encontrados`, allowOutsideClick: false})
      // Swal.showLoading()
      
      this.barChartLabels = [];
    
      this.barChartData = [
        {data: [], label: 'Pendientes'},
        {data: [], label: 'En Drive'}
      ];
      this.foldersArray = []
      this.filesArray = []
      this.arrayTiendasConVentas = [];
      this.arrayTiendasSinVentas = [];
      this.sendEmailArray = []
      this.foldersCNArray = []
      this.foldersFechasArray = [] 
      this.filesFechasCNArray = []
      this.arrayCnsMissing = [];
      this.arrayCnsCorrect = [];
      this.dateToSearch = this.completeDate(event.date.day)+"-"+this.completeDate(event.date.month)+"-"+event.date.year 
      this.dateSelected = event.date.year+"-"+event.date.month+"-"+event.date.day
      var date1 = new Date(this.dateSelected);
      date1.setDate(date1.getDate() - 31);
      console.log("dateToSearch->",this.dateToSearch) 

      this.oneDriveService.getVentas(event.date.day + "/" + event.date.month + "/" + event.date.year).subscribe(data => { 
        data.forEach(item => {
          const infoShop = {
            PARTY_SITE_NUMBER: item.PARTY_SITE_NUMBER[0],
            PARTY_RESUME: item.PARTY_SITE_NUMBER[0].substring(0, 4),
            EFECTIVO: item.EFECTIVO_POS[0],
            TERMINAL: item.TERMINAL_POS[0]
          };
          if (parseFloat(item.EFECTIVO_POS[0]) > 0 || parseFloat(item.TERMINAL_POS[0]) > 0) {
            this.arrayTiendasConVentas.push(infoShop);
          } else {
            this.arrayTiendasSinVentas.push(infoShop);
          }
        });
      }, err => {
        console.log("Error -> "+err)
        this.arrayTiendasConVentas = null
      });

      if(this.arrayCns.length>0){
        // this.getRootFiles("1CAbP7f7kEngESC-GCCcgbQXpSwPQBoCU",date1.toISOString().substring(0, 10))    // get all folders in main page in drive 
        setTimeout(() => {
          this.getFoldersCortes("1CAbP7f7kEngESC-GCCcgbQXpSwPQBoCU");
        }, 1000);
      }else{
        window.location.reload()
      }
    }else{
      this.checarSesion(false) 
    }
  }

  /**
   * this function is used to return a string number with 2 digits
   * due to onDateRangeChanged´s day value return only one digit
   * @param day 
   */
  private completeDate(day){
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
          break;
      case 2:
      endDay = '02'
          break;
      case 3:
      endDay = '03'
          break;
      case 4:
      endDay = '04'
          break;
      case 5:
      endDay = '05'
          break;
      case 6:
      endDay = '06'
          break;
      case 7:
      endDay = '07'
          break;
      case 8:
      endDay = '08'
          break;
      case 9:
      endDay = '09'
          break;
      default:
      endDay=""+day
    }
    return endDay
  } 

  /**
   * Check out if sesión is active
   */
  private checarSesion(isFirstTime){
    this.isLoggedIn  = this.isSignedIn();
    if(isFirstTime){
      if(this.isLoggedIn){
        this.signOut(isFirstTime);
      }
    }else{
      if(!this.isLoggedIn)
      this.signIn(isFirstTime);
    }
  }

  /**
   * Init client to read files inside drive
   */
  private initClient(isFirstTime) {
      gapi.load('client:auth2', () => { 
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
        }).then(() => {                   
            this.googleAuth = gapi.auth2.getAuthInstance();
            this.checarSesion(isFirstTime)
            this.appContext.Session.BreadCrumb.init();
        });
    });
    
  }

  /**
   * Return sesion status
   */
  private isSignedIn(): boolean {
      return this.googleAuth.isSignedIn.get();
  }

  /**
   * Check in drive
   */
  private signIn(isFirstTime) {
      return this.googleAuth.signIn({
          prompt: 'consent'
      }).then((googleUser: gapi.auth2.GoogleUser) => {
          this.appRepository.User.add(googleUser.getBasicProfile());
          this.initClient(isFirstTime);
      });
  }

  /**
   * Check out drive
   */
  private signOut(isFirstTime): void {
      this.googleAuth.signOut();
      console.log("Sesión drive finalizada")
      if(isFirstTime){
        this.checarSesion(!isFirstTime)
      }else{
        window.location.reload();
      }
  }

  /**
   * Get all folders in main page drive
   * @param ruteId 
   * @param date 
   */
  private getRootFiles(ruteId: string,date) {
    this.appContext.Repository.File.getFolders(ruteId)
        .then((res) => {
            this.zone.run(() => {
                this.files = res
                res.forEach(element => {
                  if(element.IsFolder)
                    this.foldersArray.push(element)
                });
                if(this.foldersArray.length>0){
                  this.getFilesInsideFolder(this.globalIndex,date)
                }
            });
        });
  }

  private getFoldersCortes(idFolder:string) {
    let nameOrg = '';
    let elementDos: any = {};
    this.appContext.Repository.File.getFolders(idFolder).then((res) => {
      this.zone.run(() => {
        res.forEach(element => {
          if (element.IsFolder) {
            nameOrg = element.Name.substring(0, 4).trim();
            if (nameOrg == 'CAN' || nameOrg == 'COL' || nameOrg == 'GTM' || nameOrg == 'TEX' || 
                nameOrg == 'VCAT' || nameOrg == 'VCR' || nameOrg == 'VTH') {
              element.Org = nameOrg;
              elementDos = {
                folderName: element.Name,
                folderId: element.Id,
                folderOrg: element.Org
              };
              this.foldersCNArray.push(elementDos);
              this.getFolderFechasForCN(elementDos);
            }
          }
        });
      });
    });
  }

  private getFolderFechasForCN(cn: any) {
    let fechaFolder = '';
    let fechaFiltro = this.dateToSearch.substring(6) + '-' + this.dateToSearch.substring(3, 5);
    const regex = /^(20\d{2}-(0[1-9]|1[0-2]) [A-Za-z]{4,10})$/;
    let elementTres: any = {};
    this.appContext.Repository.File.getFolders(cn.folderId).then((res) => {
      this.zone.run(() => {
        res.forEach(element => {
          if (element.IsFolder) {
            if (regex.test(element.Name)) {
              fechaFolder = element.Name.substring(0, 7);
              if (fechaFiltro == fechaFolder) {
                elementTres = {
                  cn: cn.folderName,
                  org: cn.folderOrg,
                  folderId: element.Id,
                  folderFecha: element.Name.substring(0, 7)
                };
                this.foldersFechasArray.push(elementTres);
                this.getFilesInsideFolderFechas(elementTres, this.globalIndex);
              }
            }
          }
        });
      });
    });
  }

  getFilesInsideFolderFechas(folderFecha: any, index: number) {
    let date1 = new Date(this.dateSelected);
    date1.setDate(date1.getDate() - 31);
    let diaFiltro = this.dateToSearch.substring(0, 2);
    let elementQatro: any = {};
    const cn = folderFecha.org == 'VCAT'
      ? folderFecha.cn.substring(5, 11)
      : folderFecha.org == 'CAN'
        ? folderFecha.cn.substring(4, 11)
        : folderFecha.cn.substring(4, 10);
      
    this.appContext.Repository.File.getFiles(folderFecha.folderId, date1.toISOString().substring(0, 10))
    .then((res) => {
        this.zone.run(() => {
            this.files = res
            for (let i = 0; i < this.files.length; i++) { // add each file found inside folder in respective array 
              const element = this.files[i];
              const posGB = element.Name.indexOf('_');
              const diaFile = element.Name.substring(posGB + 1, posGB + 3);
              if(element.IsFolder) {
                this.foldersArray.push(element)
                // console.log("folder added->",this.foldersArray.length+" / "+element.Name)
              } else {
                if (diaFiltro == diaFile) {
                  elementQatro = {
                    cn: cn,
                    org: folderFecha.org,
                    fileId: element.Id,
                    isCorrecto: true
                  };
                  this.filesFechasCNArray.push(elementQatro);
                }
              }
            }
            this.globalIndex++;
            // console.log("Is Finish? Files:", this.filesFechasCNArray.length, " Indx: ", index, " Global: ", this.globalIndex,
            //   "Folders2:", this.foldersFechasArray.length);
            if (this.globalIndex == this.foldersFechasArray.length) {
              setTimeout(() => {
                this.loader = false;
                this.checkCNWithFiles();
              }, 500);
            }
        });
    }, err => {
      console.log("getFilesInsideFolder -> Error:  " + err)
      this.loader = false
      // window.location.reload(); //reload page when there was an error
    });
  }

  private checkCNWithFiles () {
    var regionesArray = []
    if (this.filesFechasCNArray.length > 0) {
      for (let index = 0; index < this.arrayCns.length; index++) {
        const cnElement = this.arrayCns[index];
        const splitParty = cnElement.PARTY_SITE_NUMBER[0].split('_');
        let cnName = cnElement.CN_SITE_NUMBER[0];
        const cnReg = cnElement.REGION[0];
        if (cnReg == 'CANTIA') cnName = cnName.replace('CN', 'TDA');
        if (cnReg == 'REGION USA' && splitParty.length == 3) {
          cnName = 'CN' + splitParty[1];
        } else if ((cnReg == 'REGION USA' && splitParty.length != 3) || (cnElement.PARTY_SITE_NUMBER[0].includes('9999'))) {
          cnElement.ACCOUNT_NUMBER = 'UNDEFINED';
        }
        const foundReg = regionesArray.findIndex(e => e.Region === cnReg);
        const file = this.filesFechasCNArray.findIndex(e => e.cn === cnName);
        if (cnElement.ACCOUNT_NUMBER == 'CN_VIA' || cnElement.ACCOUNT_NUMBER == 'CN_TEX') {
          const haveSale = this.arrayTiendasSinVentas.findIndex(e => e.PARTY_RESUME === cnName.substring(2))
          if (haveSale == -1) {
            if (foundReg >= 0) {
              if (file >= 0) {
                regionesArray[foundReg].Correctos += 1;
              } else {
                regionesArray[foundReg].Faltantes += 1;
                this.arrayCnsMissing.push(cnElement);
              }
            } else {
              if (file >= 0) {
                regionesArray.push({
                  Region: cnReg,
                  Correctos: 1,
                  Faltantes: 0
                });
              } else {
                regionesArray.push({
                  Region: cnReg,
                  Correctos: 0,
                  Faltantes: 1
                });
                this.arrayCnsMissing.push(cnElement);
              }
            }
          }
        } else if (cnElement.ACCOUNT_NUMBER == 'CN_CAN' || cnElement.ACCOUNT_NUMBER == 'CN_VCAT' ||
                   cnElement.ACCOUNT_NUMBER == 'CN_COL' || cnElement.ACCOUNT_NUMBER == 'CN_GTM') {
          // ? cnName = CN####, TDA####
          // ? cnReg = CENTRO AMERICA
          if (cnReg != 'INTERNET'){
            if (foundReg >= 0) {
              if (file >= 0) {
                regionesArray[foundReg].Correctos += 1;
              } else {
                regionesArray[foundReg].Faltantes += 1;
                this.arrayCnsMissing.push(cnElement);
              }
            } else {
              if (file >= 0) {
                regionesArray.push({
                  Region: cnReg,
                  Correctos: 1,
                  Faltantes: 0
                });
              } else {
                regionesArray.push({
                  Region: cnReg,
                  Correctos: 0,
                  Faltantes: 1
                });
                this.arrayCnsMissing.push(cnElement);
              }
            }
          }
          
          this.arrayCnsCorrect.push({
            ACCOUNT_NUMBER: cnElement.ACCOUNT_NUMBER[0],
            CN_SITE_NUMBER: cnName,
            PARTY_SITE_NUMBER: cnElement.PARTY_SITE_NUMBER[0],
            REGION: cnReg
          });
        }
      }
      for (let i = 0; i < this.filesFechasCNArray.length; i++) {
        const file = this.filesFechasCNArray[i];
        let foundReg = -1;
        let cnReg = '';
        if (file.org == 'VCR') {
          cnReg = 'COSTA RICA';
          foundReg = regionesArray.findIndex(e => e.Region === cnReg);
          if (foundReg >= 0) {
            regionesArray[foundReg].Correctos += 1;
          } else {
            regionesArray.push({
              Region: cnReg,
              Correctos: 1,
              Faltantes: 0
            });
          }
        }
      }
      let countVCR = 0;
      for (let i = 0; i < this.foldersCNArray.length; i++) {
        const folder = this.foldersCNArray[i];
        if (folder.folderOrg == 'VCR') {
          countVCR++;
        }
      }
      for (let i = 0; i < regionesArray.length; i++) {
        switch (regionesArray[i].Region) {
          // case 'CANTIA': regionesArray[i].Faltantes = countCAN - regionesArray[i].Correctos; break;
          case 'COSTA RICA': regionesArray[i].Faltantes = countVCR - regionesArray[i].Correctos; break;
          // case 'GUATEMALA': regionesArray[i].Faltantes = countGTM - regionesArray[i].Correctos; break;
          // case 'VIANNEY CATALOG': regionesArray[i].Faltantes = countVCAT - regionesArray[i].Correctos; break;
          // case 'COLOMBIA': regionesArray[i].Faltantes = countCOL - regionesArray[i].Correctos; break;
        }
      }

      regionesArray.forEach(element => {
        this.barChartLabels.push(element.Region)
        this.barChartData[0].data.push(element.Faltantes)
        this.barChartData[1].data.push(element.Correctos)
      });
    }
  }

  /**
   * Search files inside folder
   * @param index 
   * @param date 
   */
  private getFilesInsideFolder(index,date){
    this.loopIndex = index
    console.log("loop->",index)
    const element = this.foldersArray[index];
      this.appContext.Repository.File.getFiles(element.Id,date)
      .then((res) => {
          this.zone.run(() => {
              this.files = res
              for (let i = 0; i < this.files.length; i++) { // add each file found inside folder in respective array 
                const element = this.files[i];
                if(element.IsFolder){
                  this.foldersArray.push(element)
                  // console.log("folder added->",this.foldersArray.length+" / "+element.Name)
                }else{
                  this.filesArray.push(element)
                  // console.log("file added->",this.filesArray.length+" / "+element.Name)
                }
              }
              if(index < this.foldersArray.length - 1){ 
                this.globalIndex++
                this.getFilesInsideFolder(this.globalIndex,date)
              } else{ //last positon in array
                console.log("foldersArray finish->",this.foldersArray.length)
                console.log("filesArray finish->",this.filesArray.length)
                this.globalIndex = 0
                this.checkCnInFiles()
              }
          });
      }, err => {
        console.log("getFilesInsideFolder -> Error:  "+err)
        this.loader = false
        window.location.reload(); //reload page when there was an error
      });  
    
       
  }

  /**
   * Check which cn are in files inside array   
   */
  private checkCnInFiles(){
    let searchedFilesArray = []
    this.arrayCnsMissing = this.arrayCns
    this.filesArray.forEach(element => {
      if(element.Name.includes(this.dateToSearch)){ // if file contains date searched in it´s name is added to array
        searchedFilesArray.push(element)
      }
    });
    if(searchedFilesArray.length > 0){
      for (let index = 0; index < this.arrayCns.length; index++) {
        let cnTemp = this.arrayCns[index].CN_SITE_NUMBER[0]; 
        searchedFilesArray.forEach(element => {
          if(element.Name.includes(cnTemp)){
            try {
              let position = this.arrayCnsMissing.findIndex(x => x.CN_SITE_NUMBER ==cnTemp); //get index of object 
              if(position>=0){
                var delElement = this.arrayCnsMissing[position]
                // console.log("pushed->",delElement.CN_SITE_NUMBER[0])
                this.arrayCnsCorrect.push(delElement)
              }
            } catch (error) {
              console.log("error index->",error)
            }
          } 
        });
      }
    }

    for(var i = 0; i < this.arrayCnsMissing.length; i++) {
      for(var j = 0; j < this.arrayCnsCorrect.length; j++) {
          if(this.arrayCnsMissing[i].CN_SITE_NUMBER === this.arrayCnsCorrect[j].CN_SITE_NUMBER) {
              this.arrayCnsMissing.splice(i,1)
          }
      }
    }
    this.loader = false
    // Swal.close()
    this.createChart()
  }

  /**
   * It´s called when a checkbox is checked or uncheked
   * @param item 
   */
  private onCheckChange(event,item){
    if(!event.target.checked){//if checkbox checked 
      let index = this.sendEmailArray.findIndex(x => x.SALESREP_ID==item.SALESREP_ID); //get item index in array
      this.sendEmailArray.splice(index,1)//remove object from array 
    }else{
      this.sendEmailArray.push(item) //insert object into array to send email
    }
    // event.target.checked = !event.target.checked // check status is changed 
  }

  private selectEmails() {
    if (this.botonChecked == 'Seleccionar Todo') {
      this.arrayCnsMissing.forEach(element => {
        element.checked = true;
        this.sendEmailArray.push(element);
      });
      this.botonChecked = 'Deseleccionar Todo';
    } else if (this.botonChecked == 'Deseleccionar Todo') {
      this.arrayCnsMissing.forEach(element => {
        element.checked = false;
      });
      this.sendEmailArray = [];
      this.botonChecked = 'Seleccionar Todo';
    }
  }

  /**
   * Send a email for each object in array 
   */
  private sendEmails(){
    let emailText = `Buen día.

    \nFavor de subir los cortes del día ${this.dateSelected} a Drive a la brevedad posible con la documentación ordenada y visible.

    \n Recuerdo que la política indica que los cortes deben cargarse al día siguiente de la venta.
    
    \n Del mismo modo les recuerdo que la incidencia puede darse no solamente por hecho de no cargar el corte, sino que también por cargarlo con un nombre equivocado, si ya los cargaron, les solicito por favor que revisen si están bien los nombres de los documentos.
    
    \n Gracias y saludos`



    Swal({title:'Enviando Correos...',allowOutsideClick: false})
    Swal.showLoading()
    let sent = 0 
    
    this.sendEmailArray.forEach(item => {
        let temp = item.PARTY_SITE_NUMBER[0]
        let cc = temp.split("-");
        if(cc.length == 1){
          cc = temp.split("_");
        }
        let emailTitle = cc[1] + ' Faltantes cortes en Drive'

        let emailArray = [{EMAIL:encodeURIComponent(item.EMAIL_ADDRESS), TITLE: encodeURIComponent(emailTitle), MESSAGE: encodeURIComponent(emailText), 
          PARTY_SITE_NUMBER: item.PARTY_SITE_NUMBER, RESOURCE_NAME:encodeURIComponent(item.RAZON_SOCIAL), TIPO_INCIDENCIA: 'Faltante cortes en One Drive'  }]
        
        this.oneDriveService.SendEmail(emailArray,encodeURIComponent(item.REGION), encodeURIComponent(item.ZONA)).subscribe(data => { 
          sent++
          if(sent==this.sendEmailArray.length){
            Swal.close()
            this.globalObj.alertSucces( this.sendEmailArray.length+' Notificaciones enviadas correctamente')
          }
        }, err => {
          console.log("Error-> "+err)
        })
    });
    
  }

  //***************************************************************************** */

  /**
   * Create data format to show in chart
   */
  private createChart(){
    var regionesArray = []
    for(var i = 0; i < this.arrayCnsMissing.length; i++) {
      var element = this.arrayCnsMissing[i]
      if(i==0){
        regionesArray.push({region:element.REGION[0], faltantes:1, correctos:0})
      }else{
        let position = regionesArray.findIndex(x => x.region ==element.REGION[0]); //get index of object 
        if(position>=0){
          regionesArray[position].faltantes++
        }else{
          regionesArray.push({region:element.REGION[0], faltantes:1, correctos:0})
        }
      }
    }

    for(var i = 0; i < this.arrayCnsCorrect.length; i++) {
      var element = this.arrayCnsCorrect[i]
      let position = regionesArray.findIndex(x => x.region ==element.REGION[0]); //get index of object 
        if(position>=0){
          regionesArray[position].correctos++
        }else{
          regionesArray.push({region:element.REGION[0], faltantes:0, correctos:1})
        }
    }

    regionesArray.forEach(element => {
      this.barChartLabels.push(element.region)
      this.barChartData[0].data.push(element.faltantes)
      this.barChartData[1].data.push(element.correctos)
    });

  }
  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true,
    axisX:{
      labelAngle: 90
    }
  };

  // public barChartLabels:string[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  // public barChartType:string = 'bar';
  // public barChartLegend:boolean = true;
 
  // public barChartData:any[] = [
  //   {data: [65, 59, 80, 81, 56, 55, 40], label: ''},
  //   {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
  // ];

  /**
   * Show chart or table view depending user choose 
   */
  private isChart(event){
      this.showChart = !this.showChart
      if(event.srcElement.innerHTML ==='Ver Gráfica' ){
        //// perform add action
        event.srcElement.innerHTML="Ver Tabla";
      } else if(event.srcElement.innerHTML ==='Ver Tabla'){
        //// perform remove action
        event.srcElement.innerHTML="Ver Gráfica";
      }
  }

 
  //events
  public chartClicked(e:any):void {
    // console.log(e);
  }
 
  public chartHovered(e:any):void {
    // console.log(e);
  }
 
}
