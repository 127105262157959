import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Headers, Http } from '@angular/http';

@Injectable()
export class CantiaColaboradorService {

  constructor(private http: Http, private authService: AuthService) { }
  private token = this.authService.getToken()

  public GetVentasCantia(fechai,fechaf){

    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.get('/api/getVentasCantia/' + fechai + '/' + fechaf, { headers: headers })
    .map(res => {
        const jsonResponse = res.json();
        return jsonResponse;
    });

  }

  public GetProductsInvoice(factura) {
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getProductsInvoice/' + factura, { headers: headers } )
      .map(res => res.json())
}
}
