import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Http,Headers } from '@angular/http';

@Injectable()

export class VentasShipstationService {
    constructor(private http: Http, private authService: AuthService) {}
    private token = this.authService.getToken()

    public GetVentasAmazon(fechai,fechaf){
        let headers = new Headers()
        headers.append('Authorization', this.token)

        return this.http.get('/api/getVentasAmazon/'+fechai+'/'+fechaf, { headers: headers })
            .map(res=>res.json())

    }

    public GetSalesOnlineStation(fechai,fechaf){
        let headers = new Headers()
        headers.append('Authorization', this.token)

        return this.http.get('/api/getSalesOnlineStation/'+fechai+'/'+fechaf, { headers: headers })
            .map(res=>res.json())

    }

    public GetSalesOnlineMenReport(fechai,fechaf){
        let headers = new Headers()
        headers.append('Authorization', this.token)

        return this.http.get('/api/getSalesOnlineMenReport/'+fechai+'/'+fechaf, { headers: headers })
            .map(res=>res.json())

    }

    public GetProductsInvoice(factura) {
        let headers = new Headers()
        headers.append('Authorization', this.token)
        return this.http.get('/api/getProductsInvoice/' + factura, { headers: headers } )
          .map(res => res.json())
    }

    public GetSalesOnlineEbay(fechai,fechaf) {
        let headers = new Headers()
        headers.append('Authorization', this.token)
        return this.http.get('/api/getSalesOnlineEbay/'+fechai+'/'+fechaf, { headers: headers } )
          .map(res => res.json())
    }

    public GetProductsOrder(orderid) {

        let headers = new Headers()
        headers.append('Authorization', this.token)
        return this.http.get('/api/getProductsInvoice/' + orderid, { headers: headers } )
          .map(res => res.json())
    }


}
