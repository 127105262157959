import { Component, OnInit } from '@angular/core';
import { FormasPagoService } from '../services/formas_pago.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker'
import * as moment from 'moment'
import {global_functions} from '../global_functions/global_functions';



@Component({
  selector: 'app-formas-pago',
  templateUrl: './formas-pago.component.html',
  styleUrls: ['./formas-pago.component.css']
})

export class FormasPagoComponent implements OnInit {

  private loader: boolean = true
  private formas_pago: any 
  private arr = [];
  public globalObj = global_functions;
  private moduleFormasPagoPermission = false


  constructor(private FormasPagoService: FormasPagoService) {   
  }

  private myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: true
  }

  private fecha: any = {
    formatted: moment().format('DD/MM/YYYY')
  }

  ngOnInit() {
    this.moduleFormasPagoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_formas_pago'))

    if(this.moduleFormasPagoPermission){
      this.getInitialInfo(this.fecha.formatted) 
    }else{
      this.globalObj.alertAccesDenied()
    }    
  }

  private getInitialInfo(fecha) {
    // console.log(this.fecha.formatted)
    this.loader = true
    this.FormasPagoService.GetFormasPago(btoa(fecha)).subscribe(data => {
      this.formas_pago = data
      this.loader = false
      
      var len = this.formas_pago.length;
      var temp = ""
      var currentItem = ""
      var currentStatus = ""
      for (var i = 0; i < len; i++) {
        currentItem = String(this.formas_pago[i].NUMB_FORMA_PAGO).valueOf()
        currentStatus = String(this.formas_pago[i].NUMB_STATUS).valueOf()
        if ( temp === currentItem){ 
           var objIndex = this.arr.findIndex((obj => obj.TipoPago == temp));
           if(currentStatus==="1"){
            this.arr[objIndex].Procesadas+= 1
           }else{
            this.arr[objIndex].Pendientes+= 1
           }
        }else{
          temp = currentItem
          if(currentStatus==="1"){
            this.arr.push({TipoPago: temp,Procesadas: 1,Pendientes: 0});
          }else{
            this.arr.push({TipoPago: temp,Procesadas: 0,Pendientes: 1});
          }
        }
      }
    }, err => {
      console.log("Error-> "+err)
      this.arr = []
      this.loader = false
    })
  }

  private onDateChanged(event: IMyDateModel) {
    // console.log(event)
    // console.log(event.date)
    // console.log(event.formatted)
    this.arr = []
    this.getInitialInfo(event.formatted) 
  }

  private downloadData(TipoPago) {
    console.log("button pressed ", TipoPago)
    var data, filename, link;

    var stockData = [];

    this.formas_pago.forEach(function(element) {
      console.log(element);
      var currentItem = String(element.NUMB_FORMA_PAGO).valueOf()
      if(currentItem===TipoPago){
          stockData.push(element)
      }
    });

    var csv = this.globalObj.convertArrayOfObjectsToCSV({
        data: stockData
    });
    if (csv == null) return;

    filename = TipoPago+'.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }
}
