import { Component, OnInit } from '@angular/core';
import { global_values } from '../global_functions/global_values';
import { global_functions } from '../global_functions/global_functions';
import { AdministradorService } from '../services/administrador.service';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { AuthService } from '../services/auth.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'



@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.css']
})
export class AdministradorComponent implements OnInit {

  private moduleAdminPermission = false;
  private loader = true;
  private showAdmin = true;

  private isSaving = false;
  private globalValuesObj = global_values;
  private globalObj = global_functions;
  private usersArray: any;
  private permisionsArray = [];
  private modulesArray = [];
  private regionesArray = [];
  private zonasArray = [];
  private tiposUsuarioArray = ['Administrativo', 'Regional', 'Zona'];


  constructor(private administradorService: AdministradorService,
    private authService: AuthService) {

  }


  ngOnInit() {

    // take the value of global permission_admin
    this.moduleAdminPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_admin'));

    // if user has permision to get inside this
    if (this.moduleAdminPermission) {
      // get users
      this.administradorService.GetUsersContraloria().subscribe(data => {
        this.usersArray = data;
        console.log(' - - - - - - - - - - - - - - - - - -  -')
        console.log(this.usersArray)
        this.initArrays()
        this.getPermisionsUserInit(data[0].EMAIL[0])
        this.administradorService.GetRegionesAndZonas(true).subscribe(data => {
          this.regionesArray = data
          this.administradorService.GetRegionesAndZonas(false).subscribe(data => {
            this.zonasArray = data
            this.loader = false
          }, err => {
            console.log("Error-> " + err)
            this.loader = false
          })
        }, err => {
          console.log("Error-> " + err)
          this.loader = false
        })
      }, err => {
        console.log("Error-> " + err)
        this.loader = false
      })
    } else { // user whitout permission
      this.globalObj.alertAccesDenied()
    }
  }

  /**
   * Get permisions saved in DB and set it inside permisionsArray
   * @param strUser 
   */
  private getPermisionsUserInit(strUser) {
    this.authService.userPermisions(strUser).subscribe(data => { //get user permisions from oracle DB
      this.permisionsArray = data
      this.setPermisions()
    }, err => {
      console.log("userPermisions -> Error:  " + err)
      this.permisionsArray = []
    })
  }

  /**
   * Set arrays initial values
   */
  private initArrays() {
    this.permisionsArray = []
    this.modulesArray = [{ value: this.globalValuesObj.moduleSemaforo, name: 'Semaforo de Reportes', permision: false },
    { value: this.globalValuesObj.moduleCn, name: 'Centros de Negocio', permision: false },
    { value: this.globalValuesObj.moduleTransacciones, name: 'Transacciones Pendientes', permision: false },
    { value: this.globalValuesObj.moduleFormasPago, name: 'Formas de Pago', permision: false },
    { value: this.globalValuesObj.moduleRespaldoEfectivo, name: 'Respaldo de Efectivos', permision: false },
    { value: this.globalValuesObj.moduleSeguimiento, name: 'Seguimiento de Efectivos', permision: false },
    { value: this.globalValuesObj.moduleBanco, name: 'Banco', permision: false },
    { value: this.globalValuesObj.moduleMovSAB, name: 'Movimientos SAB', permision: false },
    { value: this.globalValuesObj.moduleApManuales, name: 'Aplicaciones Manuales', permision: false },
    { value: this.globalValuesObj.moduleActividades, name: 'Actividades', permision: false },
    { value: this.globalValuesObj.moduleOneDrive, name: 'One Drive', permision: false },
    { value: this.globalValuesObj.modulePendientesDepositos, name: 'Pendientes Depósitos', permision: false },
    { value: this.globalValuesObj.moduleIncidencias, name: 'Incidencias', permision: false },
    { value: this.globalValuesObj.moduleVentasLinea, name: 'Ventas en Linea Colombia', permision: false },
    { value: this.globalValuesObj.moduleVentasAmazon, name: 'Ventas en Linea ShipStation', permision: false },
    { value: this.globalValuesObj.moduleVentasCantia, name: 'Ventas en Linea Cantia Colaborador', permision: false },
    { value: this.globalValuesObj.moduleAdmin, name: 'Administrador de Permisos', permision: false }
    ];
  }

  /**
   * Get permisions saved in DB and set it inside permisionsArray
   */
  private getPermisionsUser() {
    this.initArrays() // set arrays with initial values

    this.authService.userPermisions(this.userSelected()).subscribe(data => { //get user permisions from oracle DB
      this.permisionsArray = data
      this.setPermisions() //check modules wich user selected has permision
    }, err => {
      console.log("userPermisions -> Error:  " + err)
      this.permisionsArray = []
    })
  }


  /**
    * Change permisions to true when module is in permisionsArray
    */
  private setPermisions() {
    this.permisionsArray.forEach(element => { //check modules who user selected has permision
      let index = this.modulesArray.findIndex(x => x.value === parseInt(element.VISTA_PERMISO[0])); //get index of object 
      this.modulesArray[index].permision = true  // change permisión to true
    });
  }

  /**
   * It´s called when checkbox status change
   * @param moduleValue 
   */
  private changePermision(moduleValue) {
    let index = this.modulesArray.findIndex(x => x.value === moduleValue); //get index of object 
    this.modulesArray[index].permision = !this.modulesArray[index].permision  // change permisión
  }

  /**
   * Save permisions checked for user 
   */
  private save() {
    this.isSaving = true
    let arrInt = []
    this.modulesArray.forEach(element => { // count how many permision are checked 
      if (element.permision) { // if checked 
        arrInt.push(element.value)
      }
    });
    this.administradorService.GuardarPermisos(this.userSelected(), arrInt).subscribe(data => { //send array with permisions cheked
      let result = data
      this.isSaving = false
      this.globalObj.alertSucces('Permisos aplicados')
    }, err => {
      console.log("Error-> " + err)
    })

  }

  /**
   * Return current user in option select
   */
  private userSelected() {
    var e = (document.getElementById("optionUsers")) as HTMLSelectElement;
    var strUser = e.options[e.selectedIndex].value; //get value from option selected
    return strUser
  }

  /**
   * Show swal alert
  */
  private showAdminView(showAdmin) {
    this.showAdmin = showAdmin
  }


  private createUser() {
    var tvEmail = (document.getElementById("new_email")) as HTMLSelectElement;
    var selTipo = (document.getElementById("new_tipo_usuario")) as HTMLSelectElement;
    var selRegion = (document.getElementById("new_region")) as HTMLSelectElement;
    var selZona = (document.getElementById("new_zona")) as HTMLSelectElement;

    Swal({ title: '', text: '', allowOutsideClick: false })
    Swal.showLoading()

    let regionSelected = 'DEFAULT'
    if (selRegion.options[selRegion.selectedIndex].value != '') {
      regionSelected = selRegion.options[selRegion.selectedIndex].value
    }

    let zonaSelected = 'DEFAULT'
    if (selZona.options[selZona.selectedIndex].value != '') {
      zonaSelected = selZona.options[selZona.selectedIndex].value
    }

    this.administradorService.CreateUser(encodeURIComponent(tvEmail.value), encodeURIComponent(selTipo.options[selTipo.selectedIndex].value),
      encodeURIComponent(regionSelected), encodeURIComponent(zonaSelected)).subscribe(data => {
        Swal.close()
        this.globalObj.alertSucces('Usuario creado')
        setTimeout(() => { window.location.reload() }, 4000)
      }, err => {
        console.log("Error-> " + err)
      })


  }
}
