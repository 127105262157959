import { Component, OnInit } from '@angular/core';
import {IMyDrpOptions, IMyDateRangeModel} from 'mydaterangepicker';
import * as moment from 'moment'
import { AplicacionesManualesService } from '../services/aplicaciones_manuales.service';
import { ToastrService } from 'ngx-toastr';  
import {global_functions} from '../global_functions/global_functions';



import Swal from 'sweetalert2/dist/sweetalert2.js'
 
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-aplicaciones-manuales',
  templateUrl: './aplicaciones-manuales.component.html',
  styleUrls: ['./aplicaciones-manuales.component.css']
})
export class AplicacionesManualesComponent implements OnInit {

  private loader: boolean = true
  private isSaving: boolean = false
  private isApplying: boolean = false


  private arrayRecibos = []
  private arrayFacturas = []
  private arrayCns: any
  private arrayCnsOriginal: any
  private arrayRecibosACobrar = []
  private arrayFacturasAAplicar = []

  private valueCn: number
  private orgCn: number


  private eventStart: string
  private eventEnd: string

  private total_a_aplicar: number = 0.0
  private dif_a_pagar:number = 0.0

  private moduleApManualesPermission = false
  private globalObj = global_functions;


  constructor(private AplicacionesManualesService: AplicacionesManualesService,
    private toastr: ToastrService) { }

  private endDate = moment().format('DD/MM/YYYY')  
  private startDate = moment().subtract(7, 'day').format('DD/MM/YYYY');
  private model: any = {beginDate: this.startDate, endDate: this.endDate};


  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
  };
  

  ngOnInit() {
    this.moduleApManualesPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_ap_manuales'))

    if(this.moduleApManualesPermission){
      this.AplicacionesManualesService.GetCNsList().subscribe(data => { //call getCNsList in aplicaciones_manuales.js
        this.arrayCnsOriginal = data
        this.arrayCns = [];
        this.arrayCnsOriginal.forEach(cn => {
          if (cn.ACCOUNT_NUMBER == 'CN_VIA' || cn.ACCOUNT_NUMBER == 'CN_TEX') {
            this.arrayCns.push(cn);
          }
        });
        this.loader = false //hide loader from screeen
        this.confInit()
        console.log("GetCNsList -> correct")
        
      }, err => {
        console.log("GetCNsList -> Error:  "+err)
        this.arrayCns = []
        this.loader = false  //hide loader from screeen
      })
    }else{
      this.globalObj.alertAccesDenied()
    }
    
  }

  /**
   * It´s called when a date range is selected on picker date
   * @param event 
   */
  onDateRangeChanged(event: IMyDateRangeModel) {
    // event properties are: event.beginDate, event.endDate, event.formatted,
    // event.beginEpoc and event.endEpoc
    
     this.eventStart = this.completeDate(event.beginDate.day) +"/"+ this.completeDate(event.beginDate.month)+"/"+ event.beginDate.year
     this.eventEnd = this.completeDate(event.endDate.day) +"/"+ this.completeDate(event.endDate.month)+"/"+ event.endDate.year
  }

  /**
   * this function is used to return a string number with 2 digits
   * due to onDateRangeChanged´s day value return only one digit
   * @param day 
   */
  private completeDate(day){
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
          break;
      case 2:
      endDay = '02'
          break;
      case 3:
      endDay = '03'
          break;
      case 4:
      endDay = '04'
          break;
      case 5:
      endDay = '05'
          break;
      case 6:
      endDay = '06'
          break;
      case 7:
      endDay = '07'
          break;
      case 8:
      endDay = '08'
          break;
      case 9:
      endDay = '09'
          break;
      default:
      endDay=""+day
    }
    return endDay
  } 

  /**
   * It´s called when a different CN is selected by user
   * @param value 
   */
  private cnChange(value){
    this.cleanInitialData()
    this.valueCn = value  //set to valueCn PARTY_SITE_NUMBER value of the CN selected
    let index = this.arrayCns.findIndex(x => x.PARTY_SITE_NUMBER==this.valueCn); //get item index in array
    this.orgCn = this.arrayCns[index].ORG_ID[0] //get ORG_ID from array
  }

  /**
   * It´s used to assing the initial values to variables used in data request
   */
  private confInit(){
      if(this.arrayCns.length>0){
        this.valueCn = this.arrayCns[0].PARTY_SITE_NUMBER[0]// set to valueCn the first PARTY_SITE_NUMBER value in array
        this.orgCn = this.arrayCns[0].ORG_ID[0] // set to orgCn the first ORG_ID value in array
      }
      this.eventStart = this.startDate // set to eventStart the calendar picker´s start date
      this.eventEnd = this.endDate // set to eventEnd the calendar picker´s end date
  }

  /**
   * It´s called when search button is clicked by user
   */
  private search(){
    this.cleanInitialData()
    this.isSaving = true //show saving button and hide save button 
    this.AplicacionesManualesService.GetFacRecPendientes(this.valueCn,btoa(this.eventStart), btoa(this.eventEnd)).subscribe(data => {//call getFacRecPendientes in aplicaciones_manuales.js
      try {
        if(data.RECIBOS[0]!=""){// if xml document has recibos items
          this.arrayRecibos = data.RECIBOS[0].item
        }
        if(data.FACTURAS[0]!=""){// if xml document has facturas items
          this.arrayFacturas = data.FACTURAS[0].item
        }
        console.log("GetFacRecPendientes -> correct")
        this.isSaving = false//show save button and hide saving button 
      } catch (error) {
        console.log("GetFacRecPendientes -> Error:  "+error)
        this.isSaving = false
      }
    }, err => {//if error means that xlm document is empty or an error has succeed
      console.log("GetFacRecPendientes -> Error:  "+err)
      this.isSaving = false
    })
  }

   /**
   * Clean array and variable values 
   */
  private cleanInitialData(){
    this.arrayRecibos = []
    this.arrayFacturas = []
    this.cleanDataToApply()
  }

  /**
   *  Clean array and variable values 
   */
  private cleanDataToApply(){
    this.arrayRecibosACobrar = []
    this.arrayFacturasAAplicar = []
    this.total_a_aplicar = 0.0
    this.dif_a_pagar = 0.0
  }

  /**
   * It´s called when a option button is selected
   * @param item 
   */
  private onSelectionChange(item){ 
    this.uncheckAll()
    this.cleanDataToApply() 
    this.arrayRecibosACobrar.push({"FOLIO":item.FOLIO, "POR_COBRAR":item.POR_APLICAR}) //add data of factura selected to array
    this.dif_a_pagar = item.POR_APLICAR
  }

  /**
   * It´s called when a checkbox is checked or uncheked
   * @param item 
   */
  private onCheckChange(event,item){
      this.dif_a_pagar = this.arrayRecibosACobrar[0].POR_COBRAR[0] - this.total_a_aplicar
      if(event.target.checked){//if checkbox checked 
        // if((item.POR_APLICAR*-1) <= this.dif_a_pagar){ // if factura amount to apply is less than recibo amount remaining
        //   this.total_a_aplicar+=(item.POR_APLICAR*-1) //subtract factura amount to recibo
        //   this.arrayFacturasAAplicar.push({"FOLIO":item.FOLIO, "POR_APLICAR":item.POR_APLICAR}) //add into array to apply
        // }else{
        //   event.target.checked = !event.target.checked // check is canceled
        // }
        this.total_a_aplicar+=(item.POR_APLICAR*-1) //subtract factura amount to recibo
        this.arrayFacturasAAplicar.push({"FOLIO":item.FOLIO, "POR_APLICAR":item.POR_APLICAR}) //add into array to apply
          
      }else{ //if checkbox unchecked 
        let index = this.arrayFacturasAAplicar.findIndex(x => x.FOLIO==item.FOLIO); //get item index in array
        if(index!=-1){
          this.total_a_aplicar-=(item.POR_APLICAR*-1) // return factura amount to total
          this.arrayFacturasAAplicar.splice(index,1) // delete factura uncheked from array
        }
      }      
  }

  /**
   * Uncheck all checkboxes
   */
  private uncheckAll() {
    var checkboxes = document.getElementsByTagName('input'); //get all checkboxes
    if(checkboxes.length>0){
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;  //uncheck
        }
      }
    }
  }

  /**
   * Return  difference between two numbers 
   * @param a 
   * @param b 
   */
  private getDifferenceApManual (a, b) { 
    return Math.abs(a - b).toFixed(2); //return number with 2 digits after dot
  }

  /**
   * Return amount remaining to apply
   * @param a 
   * @param b 
   */
  private getRemaining(a,b){
    if(a>b){
      return this.getDifferenceApManual(a,b)
    }else{
      return 0
    }
  }

  /**
   * Apply all facturas selected
   */
  private aplicar(){
    let counter = 0
    this.isApplying = true //show applying button
    this.arrayFacturasAAplicar.forEach(element => {
      this.AplicacionesManualesService.AplicarFactura(this.arrayRecibosACobrar[0].FOLIO[0],element.FOLIO[0],element.POR_APLICAR[0],this.orgCn).subscribe(data => {//call getFacRecPendientes in aplicaciones_manuales.js
        counter++
        if(counter==this.arrayFacturasAAplicar.length){
          this.isApplying = false //hide  applying button
          this.globalObj.alertSucces('Facturas aplicadas') 
          this.search() // search again to show updated data
        }
      }, err => {//if error means that xlm document is empty or an error has succeed
        console.log("AplicacionesManualesService -> Error:  "+err)
        this.isApplying = false
        this.search()
      })
    });

  }

}
