import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class VentasLineaService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()
  
  public GetVentasLinea(fechai, fechaf) {
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getVentasLinea/'+fechai+'/'+fechaf, { headers: headers } )
      .map(res => res.json())
  }
  
  public GetProductosFactura(factura) {
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getProductosFactura/' + factura, { headers: headers } )
      .map(res => res.json())
  }
  
  public GetProductosOrden(orden) {
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getProductosOrden/' + orden, { headers: headers } )
      .map(res => res.json())
  }
}
