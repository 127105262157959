import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';
import {MatTabsModule} from '@angular/material/tabs';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app.routing';
import { PublicComponent } from './layout/public/public.component';
import { SecureComponent } from './layout/secure/secure.component';
import { AuthService } from './services/auth.service';
import { HttpModule } from '@angular/http';
import { AuthGuard } from './auth.guard';
import { CentrosNegocioComponent } from './centros-negocio/centros-negocio.component';
import { UsersService } from './services/users.service';
import { CentrosnegocioService } from './services/centrosnegocio.service';
import { SemaforoComponent } from './semaforo/semaforo.component';
import { SemaforoService } from './services/semaforo.service';
import { TransaccionesComponent } from './transacciones/transacciones.component';
import { TransaccionesService } from './services/transacciones.service';
import { FormasPagoComponent } from './formas-pago/formas-pago.component';
import { RespaldoComponent } from './respaldo/respaldo.component';
import { FormasPagoService } from './services/formas_pago.service';
import { RespaldoDiaService } from './services/respaldo_dia.service';
import {DataTableModule} from "angular-6-datatable";
import { GrdFilterPipe } from './pipe/grd-filter.pipe';
import { SeguimientoEfectivosComponent } from './seguimiento-efectivos/seguimiento-efectivos.component';
import { BancoVianneyComponent } from './banco-vianney/banco-vianney.component';
import { BancoVianneyService } from './services/banco_vianney.service';
import { global_functions } from './global_functions/global_functions';
import { SeguimientoEfectivosService } from './services/seguimiento_efectivos.service';
import { DaterangepickerModule } from 'angular-2-daterangepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { MovimientosSabComponent } from './movimientos-sab/movimientos-sab.component';
import { MovimientosSABService } from './services/movimientos-sab.service';
import { AplicacionesManualesComponent } from './aplicaciones-manuales/aplicaciones-manuales.component';
import { AplicacionesManualesService } from './services/aplicaciones_manuales.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule, DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AdministradorComponent } from './administrador/administrador.component';
import { AdministradorService } from './services/administrador.service';
import { ChecklistModule } from 'angular-checklist';
import { ReactiveFormsModule } from '@angular/forms';
import { ActividadesComponent } from './actividades/actividades.component';
import { ActividadesService } from './services/actividades.service';
import { OneDriveComponent } from './one-drive/one-drive.component';
import { PendientesDepositosComponent } from './pendientes-depositos/pendientes-depositos.component';
import { GapiSession } from '../infrastructure/sessions/gapi.session';
import { AppContext } from '../infrastructure/app.context';
import { AppSession } from '../infrastructure/sessions/app.session';
import { FileSession } from '../infrastructure/sessions/file.session';
import { UserSession } from '../infrastructure/sessions/user.session';
import { AppRepository } from '../infrastructure/repositories/app.repository';
import { BreadCrumbSession } from '../infrastructure/sessions/breadcrumb.session';
import { FileRepository } from '../infrastructure/repositories/file.repository';
import { UserRepository } from '../infrastructure/repositories/user.repository';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { OneDriveService } from './services/one_drive.service';
import { PendientesDepositosService } from './services/pendientes_depositos.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { IncidenciasComponent } from './incidencias/incidencias.component';
import { IncidenciasService } from './services/incidencias.service';
import { HerramientasComponent } from './herramientas/herramientas.component';
import { VentasLineaService } from './services/ventas_linea.service';
import { VentasLineaComponent } from './ventas-linea/ventas-linea.component';
import { VentasLineaSsComponent } from './ventas-linea-ss/ventas-linea-ss.component';
import { VentasShipstationService } from './services/ventas-shipstation.service';
import { CantiaColaboradorComponent } from './cantia-colaborador/cantia-colaborador.component';
import { CantiaColaboradorService } from './services/cantia-colaborador.service';

// export function initGapi(gapiSession: GapiSession) {
//   return () => gapiSession.initClient();
// }


@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    SecureComponent,
    LoginComponent,
    HomeComponent,
    CentrosNegocioComponent,
    SemaforoComponent,
    TransaccionesComponent,
    FormasPagoComponent,
    RespaldoComponent,
    GrdFilterPipe,
    SeguimientoEfectivosComponent,
    BancoVianneyComponent,
    global_functions,
    MovimientosSabComponent,
    AplicacionesManualesComponent,
    AdministradorComponent,
    ActividadesComponent,
    OneDriveComponent,
    PendientesDepositosComponent,
    IncidenciasComponent,
    HerramientasComponent,
    VentasLineaComponent,
    VentasLineaSsComponent,
    CantiaColaboradorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    MatTabsModule,
    MyDatePickerModule,
    DataTableModule, 
    DaterangepickerModule,
    MyDateRangePickerModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    CommonModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
    }),
    ChecklistModule,
    ReactiveFormsModule,
    ChartsModule,
    NgbModule,
    NgxSmartModalModule.forRoot()
  ],
  providers: [
    AuthGuard, 
    AuthService,
    UsersService,
    CentrosnegocioService,
    CantiaColaboradorService,
    SemaforoService,
    TransaccionesService,
    FormasPagoService,
    RespaldoDiaService,
    BancoVianneyService,
    SeguimientoEfectivosService,
    MovimientosSABService,
    AplicacionesManualesService,
    AdministradorService,
    ActividadesService,
    OneDriveService,
    PendientesDepositosService,
    IncidenciasService,
    VentasLineaService,
    VentasShipstationService,

    // { provide: APP_INITIALIZER, useFactory: initGapi, deps: [GapiSession], multi: true },
    AppContext,
    AppSession,
    FileSession,
    GapiSession,
    UserSession,
    AppRepository,
    BreadCrumbSession,
    FileRepository,
    UserRepository,
    [DatePipe]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
