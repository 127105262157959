import { Component, OnInit } from '@angular/core';
import { global_functions } from '../global_functions/global_functions';
import { VentasShipstationService } from '../services/ventas-shipstation.service';
import * as moment from 'moment'
import { IMyDateRangeModel, IMyDrpOptions } from 'mydaterangepicker';


@Component({
  selector: 'app-ventas-linea-ss',
  templateUrl: './ventas-linea-ss.component.html',
  styleUrls: ['./ventas-linea-ss.component.css']
})

export class VentasLineaSsComponent implements OnInit {

  private loader: boolean = true
  private showDetail: boolean = false
  private productsInvoice: any 
  private ventas_shipstation: any 
  private ventas_recibo_factura: any;
  private ventas_recibo_factura_filter: any;
  private ventas_shipstation_filter: any; 
  private ventas_ebay:any;
  private shipstation_orders: any;
  private ventas_ebay_filter: any;
  private ventas_men_report: any;
  private moduleVentasAmazonPermission = false
  private totalif: number = 0;
  private totaldf: number = 0;
  private totalf: number = 0;
  private totalord: number = 0;
  private eventStart = ''
  private eventEnd = ''
  private start_date_station = '' 
  private end_date_station = '' 
  public globalObj = global_functions;
  private unidadesArray = [{ option: 'TODOS' }, { option: 'CON SALDO' }, { option: 'APLICADAS' }];
  private unidadSelected = 'TODOS';
  private selectedTabIndex = 0;

  constructor( private sevice: VentasShipstationService) { }

  myDateRangePickerOptions: IMyDrpOptions = {

    dateFormat: 'dd/mm/yyyy',
    showSelectorArrow: true,
    monthSelector: true,
    yearSelector: true,
    
  };

  private fechai = moment().subtract(7, 'day').format('DD/MM/YYYY');
  private fechaf = moment().format('DD/MM/YYYY');

  private model: any = { beginDate: this.fechai, endDate: this.fechaf };

  ngOnInit() {

    this.moduleVentasAmazonPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_amazon'))

    if (this.moduleVentasAmazonPermission){
      
      this.getSalesAmazon(this.fechai,this.fechaf);

      this.GetSalesStation(this.fechai,this.fechaf);

      this.getSalesEbay(this.fechai,this.fechaf);

      this.GetSalesMenReport(this.fechai,this.fechaf)

    }else{
      this.globalObj.alertAccesDenied()
    }

  }

  private getSalesAmazon(fechai,fechaf){
    
    this.loader = true

    this.sevice.GetVentasAmazon(btoa(fechai), btoa(fechaf)).subscribe((data)=>{
      
      this.ventas_shipstation = data 

      this.ventas_shipstation_filter = this.ventas_shipstation

      this.loader = false
      
    },err => {
      console.log('Error ->' + err);
      this.ventas_shipstation = []
      this.loader = false
      
    })
  }

  private getSalesEbay(fechai,fechaf){

    this.loader = true

    this.sevice.GetSalesOnlineEbay(btoa(fechai), btoa(fechaf)).subscribe((data)=>{ 
        
      this.ventas_ebay = data

      this.ventas_ebay_filter = this.ventas_ebay

      this.loader = false
      
    },err => {
      console.log('Error ->' + err);
      this.ventas_ebay = []
      this.loader = false
      
    })
  }

  private GetSalesStation(fechai,fechaf){
    
    this.loader = true

    this.sevice.GetSalesOnlineStation(btoa(fechai), btoa(fechaf)).subscribe((data)=>{
      
      this.ventas_recibo_factura = data 

      for (let i = 0; i < this.ventas_recibo_factura.length; i++) {
        const e = this.ventas_recibo_factura[i];
       
        this.ventas_recibo_factura[i].MONTO_RECIBO[0] = parseFloat(e.MONTO_RECIBO[0]) * -1;

      }
      this.unidadSelected = 'TODOS';
      this.ventas_recibo_factura_filter = this.ventas_recibo_factura;
      this.loader = false
      
    },err => {
      console.log('Error ->' + err);
      this.ventas_recibo_factura = []
      this.loader = false
      
    })
  }

  private GetSalesMenReport(fechai,fechaf){
    
    this.loader = true

    this.sevice.GetSalesOnlineMenReport(btoa(fechai), btoa(fechaf)).subscribe((data)=>{
      
      this.ventas_men_report = data 

      this.loader = false
      
    },err => {
      console.log('Error ->' + err);
      this.ventas_men_report = []
      this.loader = false
      
    })
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
   
    this.ventas_shipstation = [];
    this.ventas_recibo_factura = []
    this.ventas_ebay = []
    this.eventStart = this.pad(event.beginDate.year) + "-" + this.pad(event.beginDate.month) + "-" + event.beginDate.day
    this.eventEnd = this.pad(event.endDate.year) + "-" + this.pad(event.endDate.month) + "-" + event.endDate.day
    this.start_date_station = this.pad(event.beginDate.day) + "-" + this.pad(event.beginDate.month) + "-" + this.pad(event.beginDate.year)
    this.end_date_station = this.pad(event.endDate.day) + "-" + this.pad(event.endDate.month) + "-" + this.pad(event.endDate.year)

    this.getSalesAmazon(this.eventStart, this.eventEnd)
    this.GetSalesStation(this.start_date_station, this.end_date_station)
    this.getSalesEbay(this.start_date_station, this.end_date_station)
    this.GetSalesMenReport(this.start_date_station, this.end_date_station)
  }

  private pad (d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  private downloadData() {

    switch(this.selectedTabIndex) {
      case 0:
        this.globalObj.prepareDownloadAmazon("Ventas en Linea - Shipstation (AMAZON)", this.ventas_shipstation_filter)
        break;
      case 1:
        this.globalObj.prepareDownload("Ventas en Linea - Shipstation (EBAY)", this.ventas_ebay)
        break;
      case 2:
        this.globalObj.prepareDownload("Ventas en Linea - Shipstation (Recibo vs factura)", this.ventas_recibo_factura_filter)
        break;
      case 3:
        this.globalObj.prepareDownload("Ventas en Linea - Shipstation (MenReport)", this.ventas_men_report)
        break;

      default:
        break;
    }
  }

  private isDetail(bool, item) {
    
    this.loader = true
    this.showDetail = bool;
    if (bool) {
      
      this.getProductsInvoice(item.NUMERO_FACTURA[0]);
      this.getProductsOrder(item.NUMERO_ORDEN[0]);
    }
    this.loader = false
  }

  private getProductsOrder(orderId:string){

    if (orderId != '') {
      this.sevice.GetProductsOrder(orderId).subscribe(data => {
        
        this.shipstation_orders = data
        this.shipstation_orders.forEach(e => {
          this.totalord += parseInt(e.IMPORTE[0]) * e.CANTIDAD[0];
          
        });
      }, err => {
        console.log("Error-> "+err)
        this.shipstation_orders = []
        this.loader = false
      })
    }
  }

  
  private getProductsInvoice(factura: string) {
    
    if (factura != '') {
      this.sevice.GetProductsInvoice(factura).subscribe(data => {
        
        this.productsInvoice = data
        this.productsInvoice.forEach(e => {
          this.totalif += parseInt(e.IMPORTE[0]);
          this.totaldf += parseInt(e.DESCUENTO[0]);
        });
        this.totalf = this.totalif - this.totaldf;
      }, err => {
        console.log("Error-> "+err)
        this.productsInvoice = []
        this.loader = false
      })
    }
  }

  private filterInfo (event) {
    if (event.button == -1 && event.pointerId == 0) {
      switch (this.unidadSelected) {
        case "TODOS": this.ventas_recibo_factura_filter = this.ventas_recibo_factura; break;
        case "CON SALDO":
          this.ventas_recibo_factura_filter = [];

          this.ventas_recibo_factura.forEach(e => {

            if (e.MONTO_POR_APLICAR[0] != 0) {
              this.ventas_recibo_factura_filter.push(e);
            }

          });
          break;
        case "APLICADAS":
          this.ventas_recibo_factura_filter = [];
          this.ventas_recibo_factura.forEach(e => {
            if (e.MONTO_POR_APLICAR[0] == 0) {
              this.ventas_recibo_factura_filter.push(e);
            }
          });
          break;
      }
    } 
  }

  onTabChanged(index: number) {
    this.selectedTabIndex = index;
  }

}
