import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class AdministradorService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()   
  
  public GetUsersContraloria(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getUsersContraloria' , { headers: headers } )
      .map(res => res.json())
  }

  public GuardarPermisos(user, view_permision){
    return this.http.post('/api/guardarPermisos',{user: user, view_permision: view_permision})
      .map(res => res.json())
  }

  public CreateUser(email,tipo,region,zona){
    return this.http.post('/api/createUser',{email:email, tipo:tipo, region:region, zona:zona})
      .map(res => res.json())
  }

  public GetRegionesAndZonas(gettingRegiones){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getRegionesAndZonas/'+gettingRegiones , { headers: headers } )
      .map(res => res.json())
  }

}