import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';


@Injectable()
export class SeguimientoEfectivosService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()
  
  public GetSeguimientoEfectivosAyer(fechaInicio,fechaFin){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getSeguimientoEfectivosAyer/' +fechaInicio+'/'+fechaFin, { headers: headers } )
      .map(res => res.json())
  }

  public GetSeguimientoEfectivosAyerPOS(fechaInicio,fechaFin){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getSeguimientoEfectivosAyerPOS/' +fechaInicio+'/'+fechaFin, { headers: headers } )
      .map(res => res.json())
  }

  public GetSeguimientoEfectivosAyerGeneral(fechaInicio,fechaFin,unidad){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getSeguimientoEfectivosAyerGeneral/' +fechaInicio+'/'+fechaFin+'/'+unidad, { headers: headers } )
      .map(res => res.json())
  }
}
