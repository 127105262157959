import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { PublicComponent } from './layout/public/public.component';
// import { SecureComponent } from './layout/secure/secure.component';

import { PUBLIC_ROUTES } from './layout/public/public.routes'
import { SECURE_ROUTES } from './layout/secure/secure.routes';
import { PublicComponent } from './layout/public/public.component';
import { SecureComponent } from './layout/secure/secure.component';

const routes: Routes = [
    { path: '', component: PublicComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
    { path: '', component: SecureComponent, data: { title: 'Secure Views' }, children: SECURE_ROUTES },
    { path: '**', redirectTo: 'semaforo', pathMatch: 'full' }
]

/**
 * App routing module
 */
@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }