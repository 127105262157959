import { Component, OnInit } from '@angular/core';
import {global_functions} from '../global_functions/global_functions';
import {IncidenciasService} from '../../app/services/incidencias.service'
import { summaryFileName } from '@angular/compiler/src/aot/util';
import {IMyDrpOptions, IMyDateRangeModel} from 'mydaterangepicker';
import * as moment from 'moment'

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.css']
})
export class IncidenciasComponent implements OnInit {

  private moduleIncidencias: boolean = false
  private loader: boolean = false
  private showZonas: boolean = false
  private showRegiones: boolean = false

  private globalObj = global_functions;
  private incidenciasArray = []
  private cnsArray = []
  private regionesArray = []
  private zonasArray = []
  private userProfile = []

  private  faltante_terminalTotal = 0
  private deposito_destiempoTotal = 0
  private deposito_incompletoTotal = 0
  private faltante_driveTotal = 0
  private sumTotalRows = 0

  private faltante_terminalTotalRegion = 0
  private deposito_destiempoTotalRegion = 0
  private deposito_incompletoTotalRegion = 0
  private faltante_driveTotalRegion = 0
  private sumTotalRowsRegion = 0

  private  faltante_terminalTotalZona = 0
  private deposito_destiempoTotalZona = 0
  private deposito_incompletoTotalZona = 0
  private faltante_driveTotalZona = 0
  private sumTotalRowsZona = 0


  
  constructor(private incidenciasService:IncidenciasService) { }

  private endDate = moment().format('DD/MM/YYYY')  
  private startDate = moment().subtract(7, 'day').format('DD/MM/YYYY');

  
  
  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
  };

  private model: any = {beginDate: '',
                        endDate: ''};


  /**
   * It´s called when a date is selected
   * @param event 
   */
  onDateRangeChanged(event: IMyDateRangeModel) {
    // event properties are: event.beginDate, event.endDate, event.formatted,
    // event.beginEpoc and event.endEpoc
    
    let eventStart = this.completeDate(event.beginDate.day) +"/"+ this.completeDate( event.beginDate.month)+"/"+ event.beginDate.year

    let eventEnd = this.completeDate(event.endDate.day) +"/"+ this.completeDate(event.endDate.month)+"/"+ event.endDate.year
  
    this.loader = true
    this.clearData()
    this.incidenciasService.GetIncidencias(btoa(eventStart), btoa(eventEnd), encodeURIComponent(this.userProfile[0].ZONA_USUARIO), encodeURIComponent(this.userProfile[0].REGION_USUARIO)).subscribe(data => {
      this.incidenciasArray = data
      this.loader = false 
      this.generalData()
    }, err => {
      console.log("GetIncidencias -> Error:  "+err)
      this.loader = false
    })
  }

  /**
   * 
   * @param day Return 2 digits 
   */
  private completeDate(day){
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
          break;
      case 2:
      endDay = '02'
          break;
      case 3:
      endDay = '03'
          break;
      case 4:
      endDay = '04'
          break;
      case 5:
      endDay = '05'
          break;
      case 6:
      endDay = '06'
          break;
      case 7:
      endDay = '07'
          break;
      case 8:
      endDay = '08'
          break;
      case 9:
      endDay = '09'
          break;
      default:
      endDay=""+day
    }
    return endDay
  }


  ngOnInit() {
    this.moduleIncidencias = this.globalObj.validateModulePermission(localStorage.getItem('permission_incidencias')) //get permission from local storage
    if(this.moduleIncidencias){
      this.incidenciasService.GetUserProfile(JSON.parse(localStorage.getItem('currentUser')).mail).subscribe(data => {
        this.userProfile = data
        if(this.userProfile[0].TIPO_USUARIO == 'Administrativo'){
          this.showZonas = true
          this.showRegiones = true
        }else if(this.userProfile[0].TIPO_USUARIO == 'Regional'){
          this.showRegiones = true
        }
        this.loader = false
      }, err => {
        console.log("GetUserProfile -> Error:  "+err)
        window.location.reload();//reload page when there was an error
      })
      
    }else{
      this.globalObj.alertAccesDenied()
    }  
  }


/**
   * Create data format to show in chart
   */
  private generalData(){
    for(var i = 0; i < this.incidenciasArray.length; i++) {
      var element = this.incidenciasArray[i]

      let position = this.cnsArray.findIndex(x => x.name ==element.RESOURCE_NAME[0]); 

      this.sumTotalRows++

      if (position==-1){
        switch(element.TIPO_INCIDENCIA[0]){
          case'Faltante del cierre de la terminal':{
            this.cnsArray.push({name:element.RESOURCE_NAME[0], faltante_terminal:1, deposito_destiempo:0, deposito_incompleto:0, faltante_drive:0})
            this.faltante_terminalTotal++
            break;
          }
          case'Depósito a destiempo':{
            this.cnsArray.push({name:element.RESOURCE_NAME[0], faltante_terminal:0, deposito_destiempo:1, deposito_incompleto:0, faltante_drive:0})
            this.deposito_destiempoTotal++
            break;
          }case'Depósito incompleto':{
            this.cnsArray.push({name:element.RESOURCE_NAME[0], faltante_terminal:0, deposito_destiempo:0, deposito_incompleto:1, faltante_drive:0})
            this.deposito_incompletoTotal++
            break;
          }case'Faltante cortes en One Drive':{
            this.cnsArray.push({name:element.RESOURCE_NAME[0], faltante_terminal:0, deposito_destiempo:0, deposito_incompleto:0, faltante_drive:1})
            this.faltante_driveTotal++
            break;
          }
        }
      }else{
        switch(element.TIPO_INCIDENCIA[0]){
          case'Faltante del cierre de la terminal':{
            this.cnsArray[position].faltante_terminal++
            this.faltante_terminalTotal++
            break;
          }
          case'Depósito a destiempo':{
            this.cnsArray[position].deposito_destiempo++
            this.deposito_destiempoTotal++
            break;
          }case'Depósito incompleto':{
            this.cnsArray[position].deposito_incompleto++
            this.deposito_incompletoTotal++
            break;
          }case'Faltante cortes en One Drive':{
            this.cnsArray[position].faltante_drive++
            this.faltante_driveTotal++
            break;
          }
        }
      }
    }

    this.regionData()
  }

  private regionData(){
    for(var i = 0; i < this.incidenciasArray.length; i++) {
      var element = this.incidenciasArray[i]

      let position = this.regionesArray.findIndex(x => x.name ==element.REGION[0]); 

      this.sumTotalRowsRegion++


      if (position==-1){
        switch(element.TIPO_INCIDENCIA[0]){
          case'Faltante del cierre de la terminal':{
            this.regionesArray.push({name:element.REGION[0], faltante_terminal:1, deposito_destiempo:0, deposito_incompleto:0, faltante_drive:0})
            this.faltante_terminalTotalRegion++
            break;
          }
          case'Depósito a destiempo':{
            this.regionesArray.push({name:element.REGION[0], faltante_terminal:0, deposito_destiempo:1, deposito_incompleto:0, faltante_drive:0})
            this.deposito_destiempoTotalRegion++
            break;
          }case'Depósito incompleto':{
            this.regionesArray.push({name:element.REGION[0], faltante_terminal:0, deposito_destiempo:0, deposito_incompleto:1, faltante_drive:0})
            this.deposito_incompletoTotalRegion++
            break;
          }case'Faltante cortes en One Drive':{
            this.regionesArray.push({name:element.REGION[0], faltante_terminal:0, deposito_destiempo:0, deposito_incompleto:0, faltante_drive:1})
            this.faltante_driveTotalRegion++
            break;
          }
        }
      }else{
        switch(element.TIPO_INCIDENCIA[0]){
          case'Faltante del cierre de la terminal':{
            this.regionesArray[position].faltante_terminal++
            this.faltante_terminalTotalRegion++
            break;
          }
          case'Depósito a destiempo':{
            this.regionesArray[position].deposito_destiempo++
            this.deposito_destiempoTotalRegion++
            break;
          }case'Depósito incompleto':{
            this.regionesArray[position].deposito_incompleto++
            this.deposito_incompletoTotalRegion++
            break;
          }case'Faltante cortes en One Drive':{
            this.regionesArray[position].faltante_drive++
            this.faltante_driveTotalRegion++
            break;
          }
        }
      }
    }
    this.zonaData()
  }

  private zonaData(){
    for(var i = 0; i < this.incidenciasArray.length; i++) {
      var element = this.incidenciasArray[i]

      let position = this.zonasArray.findIndex(x => x.name ==element.ZONA[0]); 

      this.sumTotalRowsZona++

      if (position==-1){
        switch(element.TIPO_INCIDENCIA[0]){
          case'Faltante del cierre de la terminal':{
            this.zonasArray.push({name:element.ZONA[0], faltante_terminal:1, deposito_destiempo:0, deposito_incompleto:0, faltante_drive:0})
            this.faltante_driveTotalZona++
            break;
          }
          case'Depósito a destiempo':{
            this.zonasArray.push({name:element.ZONA[0], faltante_terminal:0, deposito_destiempo:1, deposito_incompleto:0, faltante_drive:0})
            this.deposito_destiempoTotalZona++
            break;
          }case'Depósito incompleto':{
            this.zonasArray.push({name:element.ZONA[0], faltante_terminal:0, deposito_destiempo:0, deposito_incompleto:1, faltante_drive:0})
            this.deposito_incompletoTotalZona++
            break;
          }case'Faltante cortes en One Drive':{
            this.zonasArray.push({name:element.ZONA[0], faltante_terminal:0, deposito_destiempo:0, deposito_incompleto:0, faltante_drive:1})
            this.faltante_driveTotalZona++
            break;
          }
        }
      }else{
        switch(element.TIPO_INCIDENCIA[0]){
          case'Faltante del cierre de la terminal':{
            this.zonasArray[position].faltante_terminal++
            this.faltante_terminalTotalZona++
            break;
          }
          case'Depósito a destiempo':{
            this.zonasArray[position].deposito_destiempo++
            this.deposito_destiempoTotalZona++
            break;
          }case'Depósito incompleto':{
            this.zonasArray[position].deposito_incompleto++
            this.deposito_incompletoTotalZona++
            break;
          }case'Faltante cortes en One Drive':{
            this.zonasArray[position].faltante_drive++
            this.faltante_driveTotalZona++
            break;
          }
        }
      }
    }
  }

  private clearData(){
    this.faltante_terminalTotal = 0
    this.deposito_destiempoTotal = 0
    this.deposito_incompletoTotal = 0
    this.faltante_driveTotal = 0
    this.sumTotalRows = 0

    this.faltante_terminalTotalRegion = 0
    this.deposito_destiempoTotalRegion = 0
    this.deposito_incompletoTotalRegion = 0
    this.faltante_driveTotalRegion = 0
    this.sumTotalRowsRegion = 0

    this. faltante_terminalTotalZona = 0
    this.deposito_destiempoTotalZona = 0
    this.deposito_incompletoTotalZona = 0
    this.faltante_driveTotalZona = 0
    this.sumTotalRowsZona = 0
    this.cnsArray = []
    this.zonasArray = []
    this.regionesArray = []
    this.incidenciasArray = []
  }

}
