import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';


@Injectable()
export class OneDriveService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()
  
  public SendEmail(emailArray, region, zona){
    return this.http.post('/api/sendEmail',{emailArray: emailArray, region:region, zona:zona})
      .map(res => res.json())
  }
  
  public getVentas(fecha){
    return this.http.post('/api/getVentas', {fecha: fecha})
      .map(res => res.json())
  }
}
