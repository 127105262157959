import { Component, OnInit } from '@angular/core';
import {global_functions} from '../global_functions/global_functions';
import { ActividadesService } from '../services/actividades.service';
import { AuthService } from '../services/auth.service';
import * as XLSX from 'ts-xlsx';



@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.css']
})

export class ActividadesComponent implements OnInit {

  private loader: boolean = true
  private moduleActividadesPermission = false
  private isSaving = false
  private globalObj = global_functions;

  private actividadesArray = [] 
  private unidadesArray = [] 
  private facturasArray = []
  private dataArray:any

  private dataToUploadArray = []

  private arrayBuffer:any;
  private file:File;


  constructor(private actividadesService: ActividadesService, private authService:AuthService) { }

  ngOnInit() {
    this.moduleActividadesPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_mov_sab')) //get permission from local storage

    if(this.moduleActividadesPermission){
      this.fillData()
      this.actividadesService.getActividades().subscribe(data => { //get actividades
        this.dataArray = data

        var val1 = ''
        var val2 = ''
        for (let index = 0; index < this.dataArray.length; index++) {
          val1 = data[index].ORG[0];
          if(val1!=val2){
            this.unidadesArray.push(val1)
            val2 = val1
          }
        }

        this.fillActividadesArray(this.unidadesArray[0])

        this.loader = false
      }, err => {
        console.log("Error-> "+err)
        this.loader = false
      })  
    }else{
      this.globalObj.alertAccesDenied()
    }
  }

  private fillActividadesArray(currentOrg){
    this.actividadesArray = []
    for (let index = 0; index < this.dataArray.length; index++) {
      let  element = this.dataArray[index];
      if(element.ORG == currentOrg){
        this.actividadesArray.push(element.NAME);
      }
    }
  }

  /**
   * Fill static arrays
   */
  private fillData(){
    this.facturasArray=[    
      { "Name": "FACTURA","id":0},    
      { "Name": "RECIBO","id":1}      
    ] 
  }


  /**
   * Detects when a file is updated
   * @param event 
   */
  private incomingfile(event) {
    this.file= event.target.files[0]; 
  }

  /**
   * Cleans array data showed in table
   */
  private cleanArray(){
    this.dataToUploadArray = []
    }

  /**
   * Decode excel file and put in into an array 
   */
  private Upload() {
        let fileReader = new FileReader();
          fileReader.onload = (e) => {
              this.arrayBuffer = fileReader.result;
              var data = new Uint8Array(this.arrayBuffer);
              var arr = new Array();
              for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
              var bstr = arr.join("");
              var workbook = XLSX.read(bstr, {type:"binary"});
              var first_sheet_name = workbook.SheetNames[0];
              var worksheet = workbook.Sheets[first_sheet_name];
              // console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
              this.dataToUploadArray = XLSX.utils.sheet_to_json(worksheet,{raw:true})
          }
          fileReader.readAsArrayBuffer(this.file);
  }

  /**
   * Apply Actividades
   */
  private save(){
    this.isSaving = true
    var tipoValue = this.selectValue("selectTipo") 
    var actividadValue = this.selectValue("selectActividad")                       
    var unidadValue = this.selectValue("selectUnidad")                       
                      
    if(tipoValue == "FACTURA"){
        var saveArray = []
        this.dataToUploadArray.forEach(element => {
          saveArray.push({FACTURA:element.FACTURA,
            // IMPORTE:element.IMPORTE,
            ACTIVIDAD: actividadValue,
            UNIDAD_OPERATIVA: unidadValue
          })
        });
        console.log("saveArray->",saveArray)
        this.actividadesService.SaveActividades(saveArray).subscribe(data => { //send array with actividades
          // this.isSaving = false
          // this.globalObj.alertSucces('Actividades aplicadas')
          this.dataToUploadArray = []
          this.applyActividadesFac();
        }, err => {
          console.log("Error-> "+err)
          this.isSaving = false
        })
    }else{
      var saveArray = []
      this.dataToUploadArray.forEach(element => {
        saveArray.push({FACTURA:element.FACTURA,
          // IMPORTE:element.IMPORTE,
          ACTIVIDAD: actividadValue,
          UNIDAD_OPERATIVA: unidadValue
        })
      });
      console.log("saveArray->",saveArray)
      this.actividadesService.SaveActividadesRecibos(saveArray).subscribe(data => { //send array with actividades
        // this.isSaving = false
        // this.globalObj.alertSucces('Actividades aplicadas')
        this.dataToUploadArray = []
        this.applyActividadesRec();
      }, err => {
        console.log("Error-> "+err)
        this.isSaving = false
      })
    }
  }

  private applyActividadesFac() {
    this.actividadesService.aplicarActividadesFac().subscribe(data => {
      this.isSaving = false
      this.globalObj.alertSucces('Actividades aplicadas')
      this.dataToUploadArray = []
    }, err => {
      console.log("Error-> "+err)
      this.isSaving = false
    })
  }

  private applyActividadesRec() {
    this.actividadesService.aplicarActividadesRec().subscribe(data => {
      this.isSaving = false
      this.globalObj.alertSucces('Actividades aplicadas')
      this.dataToUploadArray = []
    }, err => {
      console.log("Error-> "+err)
      this.isSaving = false
    })
  }


  /**
   * Return current value in option select
   */
  private selectValue(SelectId){
    var e = (document.getElementById(SelectId)) as HTMLSelectElement;  //get select option from html code
    var strUser = e.options[e.selectedIndex].value; //get value from option selected
    return strUser
  }


  private orgChange(value){
    this.fillActividadesArray(value)
  }
}
