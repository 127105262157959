import { Pipe } from "@angular/core";
import Swal from 'sweetalert2/dist/sweetalert2.js'


@Pipe({
    name: 'global_functions'
})
export class global_functions{
  
    static  prepareDownload(name, data){
        var data, filename, link;
    
          var stockData = [];
      
          data.forEach(function(element) {
            stockData.push(element)
          });
      
          var csv = this.convertArrayOfObjectsToCSV({
              data: data
          });

          if (csv == null) return;
      
          filename = name+'.csv';
      
          if (!csv.match(/^data:text\/csv/i)) {
              csv = 'data:text/csv;charset=utf-8,' + csv;
          }
          data = encodeURI(csv);
      
          link = document.createElement('a');
          link.setAttribute('href', data);
          link.setAttribute('download', filename);
          link.click();
      }

      static prepareDownloadAmazon( name, data ){

        var filename, link;

        var csv = this.convertArrayOfObjectsToCSVAmazon({
          data: data 
        });

        if (csv == null) return;

        filename = name +'.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }

        var encodedUri = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename);
        link.click();

      }

      static convertArrayOfObjectsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
      }
    
      static convertArrayOfObjectsToCSVAmazon(args) {
        var result, columnDelimiter, lineDelimiter, data;
      
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }
      
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
      
        var headers = [
            'PostedDate',
            'AmazonOrderId',
            'SellerSKU',
            'QuantityShipped',
            'Principal',
            'Tax',
            'GiftWrapPrice',
            'GiftWrapTax',
            'ShippingCharge',
            'ShippingTax',
            'Commission',
            'FixedClosingFee',
            'ShippingHB',
            'VariableClosingFee'
           
        ];
        
   
        result = headers.join(columnDelimiter) + lineDelimiter;
      
        data.forEach(function(item) {
            var shipment = item.ShipmentItemList[0];
    
            var line = [
                item.PostedDate,
                item.AmazonOrderId,
                shipment.SellerSKU,
                shipment.QuantityShipped
            ];
   
            shipment.ItemChargeList.forEach(function(charge) {
                line.push(charge.ChargeAmount.CurrencyAmount);
            });
   
            shipment.ItemFeeList.forEach(function(fee) {
                line.push(fee.FeeAmount.CurrencyAmount);
            });
   
            result += line.join(columnDelimiter) + lineDelimiter;
        });
      
        return result;
      }

      /**
       * Return a boolean value 
       * @param permission 
       */
      static validateModulePermission(permission){
        if(permission == 'true')
          return true
        else
          return false
      }

      static alertAccesDenied(){
        Swal({
            type: 'error',
            title: 'Acceso Denegado',
            text:"Este usuario no cuenta con los permisos necesarios para ingresar a este módulo",
            showConfirmButton: true
          }).then(function() {
            // window.location.href = '/semaforo';
          }); 
      }

      /**
       * Show succes alert
       * @param text 
       */
      static alertSucces(text){
        Swal({
            position: 'top-end',
            type: 'success',
            title: text,
            showConfirmButton: false,
            timer: 3500
          }) // show success dialog   
      }
}