import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';


@Injectable()
export class PendientesDepositosService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()
  
  // public GetPendientesGeneral(date1,date2){
  //   return this.http.post('/api/getPendientesGeneral',{date1: date1, date2: date2})
  //     .map(res => res.json())
  // }

  public GetPendientesDepositosGeneral(fechaInicio,fechaFin){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getPendientesDepositosGeneral/' +fechaInicio+'/'+fechaFin, { headers: headers } )
      .map(res => res.json())
  }

  public GetPendientesDepositosCN(fechaInicio,fechaFin){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getPendientesDepositosCN/' +fechaInicio+'/'+fechaFin, { headers: headers } )
      .map(res => res.json())
  }


  public GetPendientesDetails(sucursal,fechaInicio,fechaFin){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getPendientesDetails/' + sucursal +'/'+fechaInicio+'/'+fechaFin, { headers: headers } )
      .map(res => res.json())
  }
}
