import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import {global_functions} from '../../global_functions/global_functions';


declare const gapi: any;

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.css']
})

export class SecureComponent implements OnInit {

  public imageUrl: string = ""
  public username: string = ""
  private returnUrl: string

  private moduleSemaforoPermission = false
  private moduleCnPermission = false
  private moduleTransaccionesPermission = false
  private moduleFormasPagoPermission = false
  private moduleRespaldoEfectivoPermission = false
  private moduleSeguimientoEfectivoPermission = false
  private moduleBancoPermission = false
  private moduleMovSABPermission = false
  private moduleApManualesPermission = false
  private moduleAdminPermission = false
  private moduleActividades = false
  private moduleOneDrive = false
  private modulePendientesDepositos = false
  private moduleIncidencias = false
  private moduleVentasLinea = false
  private moduleVentasAmazon = false
  private moduleVentasCantia = false
  public anio: any;


  private globalObj = global_functions;




  constructor(
    private router: Router,
    private authService: AuthService,
    private zone: NgZone
  ) {
  }

  ngOnInit() {
    let user = this.authService.getUserInfo()
    this.imageUrl = user.imageUrl
    this.username = user.username
    this.anio = new Date().getFullYear();

    this.moduleSemaforoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_semaforo'))
    this.moduleCnPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_cn'))
    this.moduleTransaccionesPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_transacciones'))
    this.moduleFormasPagoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_formas_pago'))
    this.moduleRespaldoEfectivoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_respaldo'))
    this.moduleSeguimientoEfectivoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_seguimiento'))
    this.moduleBancoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_banco'))
    this.moduleMovSABPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_mov_sab'))
    this.moduleApManualesPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_ap_manuales'))
    this.moduleAdminPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_admin'))
    this.moduleActividades = this.globalObj.validateModulePermission(localStorage.getItem('permission_actividades'))
    this.moduleOneDrive = this.globalObj.validateModulePermission(localStorage.getItem('permission_one_drive'))
    this.modulePendientesDepositos = this.globalObj.validateModulePermission(localStorage.getItem('permission_pendientes_depositos'))
    this.moduleIncidencias = this.globalObj.validateModulePermission(localStorage.getItem('permission_incidencias'))
    this.moduleVentasLinea = this.globalObj.validateModulePermission(localStorage.getItem('permission_ventaslinea'))
    this.moduleVentasAmazon = this.globalObj.validateModulePermission(localStorage.getItem('permission_amazon'))
    this.moduleVentasCantia = this.globalObj.validateModulePermission(localStorage.getItem('permission_cantia'))
  } 

  public logout() {
    // localStorage.removeItem('currentUser')
    localStorage.clear()


    // this.router.navigateByUrl('/home')
    this.zone.run(() => this.router.navigateByUrl('/login'))
  }

}
