import { Component, OnInit } from '@angular/core';
import { RespaldoDiaService } from '../services/respaldo_dia.service';
import {global_functions} from '../global_functions/global_functions';


@Component({
  selector: 'app-respaldo',
  templateUrl: './respaldo.component.html',
  styleUrls: ['./respaldo.component.css']
})
export class RespaldoComponent implements OnInit {

  private loader: boolean = true
  private respaldo_dias: any 

  public searchText : string;
  public customerData : any;

  private moduleRespaldoEfectivoPermission = false
  private globalObj = global_functions;

  
  constructor(private RespaldoDiaService: RespaldoDiaService) { }

  ngOnInit() {
    this.moduleRespaldoEfectivoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_respaldo'))

    if(this.moduleRespaldoEfectivoPermission){
      this.getInitialInfo() 
    }else{
      this.globalObj.alertAccesDenied()
    }
  }

  private getInitialInfo() {
    this.loader = true
    this.RespaldoDiaService.GetDiaRespaldo().subscribe(data => {
      this.respaldo_dias = data
      this.loader = false
    }, err => {
      console.log("Error-> "+err)
      this.loader = false
      window.location.reload();        //reload page when there was an error
    })
  }

  private downloadData() {
    var data, filename, link;

    var stockData = [];

    this.respaldo_dias.forEach(function(element) {
      stockData.push(element)
    });

    var csv = this.convertArrayOfObjectsToCSV({
        data: stockData
    });
    if (csv == null) return;

    filename = 'Respaldo.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }

  private convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
        ctr = 0;
        keys.forEach(function(key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
  }

}
