export class global_values{

    //--- module values ---  These variables are used to control which modules users have access to.
    static moduleCn = 1;
    static moduleSemaforo = 2;
    static moduleTransacciones = 3;
    static moduleFormasPago = 4;
    static moduleRespaldoEfectivo = 5;
    static moduleSeguimiento = 6;
    static moduleBanco = 7;
    static moduleMovSAB = 8;
    static moduleApManuales = 9;
    static moduleAdmin = 10;
    static moduleActividades = 11; 
    static moduleOneDrive = 12; 
    static modulePendientesDepositos = 13; 
    static moduleIncidencias = 14; 
    static moduleVentasLinea = 15;
    static moduleVentasAmazon = 16;
    static moduleVentasCantia = 17;
    //----------------------

}



