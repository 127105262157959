import { Component, OnInit } from '@angular/core';
import { VentasLineaService } from '../services/ventas_linea.service';
import {IMyDrpOptions, IMyDateRangeModel} from 'mydaterangepicker';
import * as moment from 'moment'
import { global_functions } from '../global_functions/global_functions';

@Component({
  selector: 'app-ventas-linea',
  templateUrl: './ventas-linea.component.html',
  styleUrls: ['./ventas-linea.component.css']
})

export class VentasLineaComponent implements OnInit {

  private loader: boolean = true
  private ventas_linea: any 
  private ventas_linea_filter: any 
  private productosArrFac: any 
  private productosArrOrd: any 
  public globalObj = global_functions;
  private moduleVentasLineaPermission = false
  
  private showDetail: boolean = false
  private eventStart = ''
  private eventEnd = ''
  private totalif: number = 0;
  private totaldf: number = 0;
  private totalf: number = 0;
  private totalio: number = 0;
  private totalo: number = 0;
  private unidadesArray = [{ option: 'TODOS' }, { option: 'CON SALDO' }, { option: 'APLICADAS' }];
  private unidadSelected = 'TODOS';

  constructor(private sevice: VentasLineaService) {    }

  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
  };

  private fechai = moment().subtract(7, 'day').format('DD/MM/YYYY');
  private fechaf = moment().format('DD/MM/YYYY');
  private model: any = { beginDate: this.fechai, endDate: this.fechaf };

  ngOnInit() {
    this.moduleVentasLineaPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_ventaslinea'))

    if (this.moduleVentasLineaPermission) {
      this.getInitialInfo(this.fechai, this.fechaf);
    } else {
      this.globalObj.alertAccesDenied()
    }
  }

  private getInitialInfo(fechai, fechaf) {
    // console.log(this.fecha.formatted)
    this.loader = true
    this.sevice.GetVentasLinea(btoa(fechai), btoa(fechaf)).subscribe(data => {
      this.ventas_linea = data
      for (let i = 0; i < this.ventas_linea.length; i++) {
        const e = this.ventas_linea[i];
        this.ventas_linea[i].FECHA_ORDEN[0] = e.FECHA_ORDEN[0].substring(0, 10);
        this.ventas_linea[i].MONTO_RECIBO[0] = parseInt(e.MONTO_RECIBO[0]) * -1;
      }
      this.unidadSelected = 'TODOS';
      this.ventas_linea_filter = this.ventas_linea;
      this.loader = false
    }, err => {
      console.log("Error-> "+err)
      this.ventas_linea = []
      this.loader = false
    })
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    this.ventas_linea = [];
    this.eventStart = this.pad(event.beginDate.day) + "/" + this.pad(event.beginDate.month) + "/" + event.beginDate.year
    this.eventEnd = this.pad(event.endDate.day) + "/" + this.pad(event.endDate.month) + "/" + event.endDate.year
    this.getInitialInfo(this.eventStart, this.eventEnd)
  }

  private pad (d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }
  
  private isDetail(bool, item) {
    this.loader = true
    this.showDetail = bool;
    if (bool) {
      this.getProductosFactura(item.NUMERO_FACTURA[0]);
      this.getProductosOrden(item.NUMERO_ORDEN[0]);
    }
    this.loader = false
  }

  private getProductosFactura(factura: string) {
    if (factura != '') {
      this.sevice.GetProductosFactura(factura).subscribe(data => {
        this.productosArrFac = data
        this.productosArrFac.forEach(e => {
          this.totalif += parseInt(e.IMPORTE[0]);
          this.totaldf += parseInt(e.DESCUENTO[0]);
        });
        this.totalf = this.totalif - this.totaldf;
      }, err => {
        console.log("Error-> "+err)
        this.productosArrFac = []
        this.loader = false
      })
    }
  }

  private getProductosOrden(orden: string) {
    if (orden != '') {
      this.sevice.GetProductosOrden(orden).subscribe(data => {
        this.productosArrOrd = data
        this.productosArrOrd.forEach(e => {
          this.totalo += parseInt(e.IMPORTE[0]) * e.CANTIDAD[0];
        });
      }, err => {
        console.log("Error-> "+err)
        this.productosArrOrd = []
        this.loader = false
      })
    }
  }

  private downloadData() {
    this.globalObj.prepareDownload("Ventas en Linea - Colombia", this.ventas_linea_filter)
  }

  private filterInfo (event) {
    if (event.button == -1 && event.pointerId == 0) {
      switch (this.unidadSelected) {
        case "TODOS": this.ventas_linea_filter = this.ventas_linea; break;
        case "CON SALDO":
          this.ventas_linea_filter = [];
          this.ventas_linea.forEach(e => {
            if (e.MONTO_POR_APLICAR[0] != 0) {
              this.ventas_linea_filter.push(e);
            }
          });
          break;
        case "APLICADAS":
          this.ventas_linea_filter = [];
          this.ventas_linea.forEach(e => {
            if (e.MONTO_POR_APLICAR[0] == 0) {
              this.ventas_linea_filter.push(e);
            }
          });
          break;
      }
    } 
  }
}
