import { Component, OnInit } from '@angular/core'
import { IMyDpOptions, IMyDateModel } from 'mydatepicker'
import * as moment from 'moment'
import { global_functions } from '../global_functions/global_functions';
import { TransaccionesService } from '../services/transacciones.service';

@Component({
  selector: 'app-transacciones',
  templateUrl: './transacciones.component.html',
  styleUrls: ['./transacciones.component.css']
})
export class TransaccionesComponent implements OnInit {

  private loader: boolean = false
  private transacciones_pendientes: any
  private moduleTransaccionesPermission = false
  private globalObj = global_functions;

  private myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: true
  }

  private fecha: any = {
    formatted: moment().format('DD/MM/YYYY')
  }

  constructor(
    private transaccionesService: TransaccionesService
  ) { }

  ngOnInit() {
    this.moduleTransaccionesPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_transacciones'))

    if (this.moduleTransaccionesPermission) {
      // this.getTransacciones(this.fecha.date)
    } else {
      this.globalObj.alertAccesDenied()
    }
  }

  private getTransacciones(fecha) {
    // console.log(this.fecha.formatted)
    this.loader = true
    this.transaccionesService.GetTransaccionesPendientes(btoa(fecha)).subscribe(data => {
      this.transacciones_pendientes = data
      this.loader = false
    }, err => {
      console.log("Error-> " + err)
      this.transacciones_pendientes = null
      this.loader = false
    }/**/)
  }

  private onDateChanged(event: IMyDateModel) {
    // console.log(event)
    // console.log(event.date)
    let date = event.date.year + "-" + this.completeDate(event.date.month) + "-" + this.completeDate(event.date.day)
    console.log(date)
    this.getTransacciones(date)
  }

  /**
   * Return a value with 2 digits
   * @param day 
   */
  private completeDate(day) {
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
        break;
      case 2:
        endDay = '02'
        break;
      case 3:
        endDay = '03'
        break;
      case 4:
        endDay = '04'
        break;
      case 5:
        endDay = '05'
        break;
      case 6:
        endDay = '06'
        break;
      case 7:
        endDay = '07'
        break;
      case 8:
        endDay = '08'
        break;
      case 9:
        endDay = '09'
        break;
      default:
        endDay = "" + day
    }
    return endDay
  }


  private downloadData() {
    this.globalObj.prepareDownload("Transacciones", this.transacciones_pendientes)
  }
}
