import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core'
import { AuthService } from '../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router'
import { global_values } from '../global_functions/global_values';
import { conf } from '../../../server/conf/conf'


declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements AfterViewInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone
  ) { }

  public auth2: any;
  public returnUrl = 'semaforo';

  private arrayPermisions: any;

  private globalValues = global_values;

  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '676687949327-2etrhv8acfn8knuono1co04o727kogqj.apps.googleusercontent.com',
        // client_id: '499826383751-eure86ro8okpacup786m0onittje144h.apps.googleusercontent.com', //test
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {

        const profile = googleUser.getBasicProfile();
        console.log('profile->', profile);

        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());

        // YOUR CODE HERE
        this.authService.authentication(profile.getEmail(), profile.getName(), profile.getImageUrl()).subscribe(
          data => {
            if (!data) {
              alert('El usuario no tiene acceso a la aplicación. ');
            } else {
              console.log(profile.getEmail())
              this.authService.userPermisions(profile.getEmail()).subscribe(data => {
                this.arrayPermisions = data;
                this.configPermisionsInitial();
                this.setPermisions();
                this.zone.run(() => this.router.navigate([this.returnUrl]));

              }, err => {
                console.log(err);
                this.arrayPermisions = [];
              });
            }
          },
          error => {
            alert('El usuario no tiene acceso a la aplicación. ');

            const _error = JSON.parse(error._body);
            console.log(_error.message);
          }
        )

      }, (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      });
  }

  ngAfterViewInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/semaforo';

    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      if (user.loggedIn) {
        this.zone.run(() => this.router.navigate([this.returnUrl]));
      }
    }
    this.googleInit();
  }

  /**
   * Set false all module permisions 
   */
  private configPermisionsInitial() {

    localStorage.setItem('permission_semaforo', 'false');
    localStorage.setItem('permission_cn', 'false');
    localStorage.setItem('permission_transacciones', 'false');
    localStorage.setItem('permission_formas_pago', 'false');
    localStorage.setItem('permission_respaldo', 'false');
    localStorage.setItem('permission_seguimiento', 'false');
    localStorage.setItem('permission_banco', 'false');
    localStorage.setItem('permission_mov_sab', 'false');
    localStorage.setItem('permission_ap_manuales', 'false');
    localStorage.setItem('permission_admin', 'false');
    localStorage.setItem('permission_actividades', 'false');
    localStorage.setItem('permission_one_drive', 'false');
    localStorage.setItem('permission_pendientes_depositos', 'false');
    localStorage.setItem('permission_incidencias', 'false');
    localStorage.setItem('permission_ventaslinea', 'false');
    localStorage.setItem('permission_amazon', 'false');
    localStorage.setItem('permission_cantia', 'false');

  }

  /**
   * Change permisions to true when module is in arrayPermisions
   */
  private setPermisions() {
    this.arrayPermisions.forEach(element => {
      const elementValue = parseInt(element.VISTA_PERMISO[0], 10);
      switch (elementValue) {
        case this.globalValues.moduleCn: {
          localStorage.setItem('permission_cn', 'true');
          break;
        }
        case this.globalValues.moduleSemaforo: {
          localStorage.setItem('permission_semaforo', 'true');
          break;
        }
        case this.globalValues.moduleTransacciones: {
          localStorage.setItem('permission_transacciones', 'true');
          break;
        }
        case this.globalValues.moduleFormasPago: {
          localStorage.setItem('permission_formas_pago', 'true');
          break;
        }
        case this.globalValues.moduleRespaldoEfectivo: {
          localStorage.setItem('permission_respaldo', 'true');
          break;
        }
        case this.globalValues.moduleSeguimiento: {
          localStorage.setItem('permission_seguimiento', 'true');
          break;
        }
        case this.globalValues.moduleBanco: {
          localStorage.setItem('permission_banco', 'true');
          break;
        }
        case this.globalValues.moduleMovSAB: {
          localStorage.setItem('permission_mov_sab', 'true');
          break;
        }
        case this.globalValues.moduleApManuales: {
          localStorage.setItem('permission_ap_manuales', 'true');
          break;
        }
        case this.globalValues.moduleAdmin: {
          localStorage.setItem('permission_admin', 'true');
          break;
        }
        case this.globalValues.moduleActividades: {
          localStorage.setItem('permission_actividades', 'true');
          break;
        }
        case this.globalValues.moduleOneDrive: {
          localStorage.setItem('permission_one_drive', 'true');
          break;
        }
        case this.globalValues.modulePendientesDepositos: {
          localStorage.setItem('permission_pendientes_depositos', 'true');
          break;
        }
        case this.globalValues.moduleIncidencias: {
          localStorage.setItem('permission_incidencias', 'true');
          break;
        }
        case this.globalValues.moduleVentasLinea: {
          localStorage.setItem('permission_ventaslinea', 'true');
          break;
        }
        case this.globalValues.moduleVentasAmazon: {
          localStorage.setItem('permission_amazon', 'true');
          break;
        }
        case this.globalValues.moduleVentasCantia: {
          localStorage.setItem('permission_cantia', 'true');
          break;
        }
        default:
          break;

      }
    });
  }
}
