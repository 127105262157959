import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        //if logged in so return true        
        if (localStorage.getItem('currentUser')) {
            
            let currentUser = JSON.parse(localStorage.getItem('currentUser'))
            let accessDate = new Date(currentUser.date).getTime()
            let currentDate = new Date().getTime()
            

            var difference = currentDate - accessDate;

            var daysDifference = Math.floor(difference/1000/60/60/24);
            difference -= daysDifference*1000*60*60*24
    
           var hoursDifference = Math.floor(difference/1000/60/60);
            difference -= hoursDifference*1000*60*60
    
            var minutesDifference = Math.floor(difference/1000/60);
            difference -= minutesDifference*1000*60
    
            var secondsDifference = Math.floor(difference/1000);
            // console.log('difference = ' + daysDifference + ' day/s ' + hoursDifference + ' hour/s ' + minutesDifference + ' minute/s ' + secondsDifference + ' second/s ');

            //if Date has expired (Expiration 10h)
            if( hoursDifference < 12 ){
                return true
            } else{
                this.router.navigateByUrl('/login');
                return false;
            }
                
            // return true;
        }

        // not logged in so redirect to login page
        this.router.navigateByUrl('/login')
        return false;
    }
}