import { Component, OnInit } from '@angular/core';
import {global_functions} from '../global_functions/global_functions';
import {IMyDrpOptions, IMyDateRangeModel} from 'mydaterangepicker';
import * as moment from 'moment'
import { PendientesDepositosService } from '../services/pendientes_depositos.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-pendientes-depositos',
  templateUrl: './pendientes-depositos.component.html',
  styleUrls: ['./pendientes-depositos.component.css'],
  // add NgbModalConfig and NgbModal to the component providers
  providers: [NgbModalConfig, NgbModal]
})
export class PendientesDepositosComponent implements OnInit {
  
  private modulePendientesDepositosPermission: boolean = false
  private loader: boolean = true
  private showDetailsView: boolean = false
  private arrayGenData = []
  private arrayCNData = []
  private arrayCnDetails = []
  private totalICG = 0.0
  private totalOracle = 0.0
  private diferenciaTotal = 0.0
  private eventStart = ''
  private eventEnd = ''
  private globalObj = global_functions;
  private currentCn = ''
  

  private endDate = moment().format('DD/MM/YYYY')  
  private startDate = moment().subtract(7, 'day').format('DD/MM/YYYY');

  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
  };

  private model: any = {beginDate: '',
                        endDate: ''};


  constructor(private pendientesDepositosService :PendientesDepositosService,
    config: NgbModalConfig, private modalService: NgbModal,
    public ngxSmartModalService: NgxSmartModalService) { 
      // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  
  
  private open(content) {
      this.modalService.open(content, { size: 'lg' });
  }

  ngOnInit() {  
    this.modulePendientesDepositosPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_pendientes_depositos')) //get permission from local storage
    if(this.modulePendientesDepositosPermission){
      this.loader = false
    }else{
      this.globalObj.alertAccesDenied()
    }  
  }

  ngAfterViewInit() {
    const obj: Object = {
      prop1: 'test',
      prop2: true,
      prop3: [{a: 'a', b: 'b'}, {c: 'c', d: 'd'}],
      prop4: 327652175423
    };

    this.ngxSmartModalService.setModalData(obj, 'myModal');
  }

  

  /**
   * It´s called when a date range is selected
   * @param event 
   */
  private onDateRangeChanged(event: IMyDateRangeModel) {
    // event properties are: event.beginDate, event.endDate, event.formatted,
    // event.beginEpoc and event.endEpoc
    this.eventStart = this.completeDate(event.beginDate.day) +"/"+ this.completeDate(event.beginDate.month)+"/"+ event.beginDate.year
    this.eventEnd = this.completeDate(event.endDate.day) +"/"+ this.completeDate(event.endDate.month)+"/"+ event.endDate.year
    this.getDataGeneral(this.eventStart,this.eventEnd)
  }


  /**
   * Return a value with 2 digits
   * @param day 
   */
  private completeDate(day){
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
          break;
      case 2:
      endDay = '02'
          break;
      case 3:
      endDay = '03'
          break;
      case 4:
      endDay = '04'
          break;
      case 5:
      endDay = '05'
          break;
      case 6:
      endDay = '06'
          break;
      case 7:
      endDay = '07'
          break;
      case 8:
      endDay = '08'
          break;
      case 9:
      endDay = '09'
          break;
      default:
      endDay=""+day
    }
    return endDay
  }

  
  private getDataGeneral(fechaInicio,fechaFin) {
    this.loader = true
    this.pendientesDepositosService.GetPendientesDepositosGeneral(btoa(fechaInicio),btoa(fechaFin)).subscribe(data => {
      this.arrayGenData = data
      this.getDataCN(fechaInicio,fechaFin)
    }, err => {
      console.log("Error Oracle-> "+err)
      this.arrayGenData = null
      this.loader = false
    })
  }

  private getDataCN(fechaInicio,fechaFin) {
    this.loader = true
    this.pendientesDepositosService.GetPendientesDepositosCN(btoa(fechaInicio),btoa(fechaFin)).subscribe(data => {
      this.arrayCNData = data
      // console.log(this.arrayGenData)
      this.loader = false
    //*******************/
    }, err => {
      console.log("Error Oracle-> "+err)
      this.arrayGenData = null
      this.loader = false
    })
  }

  private showDetails(item){
    this.arrayCnDetails = []
    this.loader = true
    this.currentCn = item.CN
    this.pendientesDepositosService.GetPendientesDetails(item.SUC_FAC,btoa(this.eventStart),btoa(this.eventEnd)).subscribe(data => {
      this.arrayCnDetails = data
      this.showDetailsView = true
      this.loader = false
    }, err => {
      console.log("Error GetPendientesDepositosGeneral-> "+err)
      this.showDetailsView = true
      this.loader = false
    })
  }

  private hideDetails(){
    this.showDetailsView = false
  }

}
