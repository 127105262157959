import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()
export class MovimientosSABService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }
  private token = this.authService.getToken()   
  
  public GetCuentasBancarias(){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getCuentasBancarias' , { headers: headers } )
      .map(res => res.json())
  }

  public GetMovimientosSAB(status,cuenta,date1,date2,recibos){
    let headers = new Headers()
    headers.append('Authorization', this.token)
    return this.http.get('/api/getMovimientosSAB/'+ status +"/"+cuenta+"/"+date1+"/"+date2+"/"+recibos , { headers: headers } )
      .map(res => res.json())
  }
}
