import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth.service';

@Injectable()

export class SemaforoService {


  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  private token = this.authService.getToken()

  public getProblemas() {
    const headers = new Headers();
    headers.append('Authorization', this.token);

    return this.http.get('/api/problemas', { headers: headers })
      .map(res => res.json());
  }

  public getFP_Vales() {
    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.get('/api/fp_vales', { headers: headers })
      .map(res => res.json())
  }

  public getNoFacturadasVianney(fecha_inicio_encode, fecha_fin_encode) {
    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.get('/api/no_facturadas_vianney/' + fecha_inicio_encode + "/" + fecha_fin_encode, { headers: headers })
      .map(res => res.json())
  }

  public getNoFacturadasTextilera(fecha_inicio_encode, fecha_fin_encode) {
    let headers = new Headers()
    headers.append('Authorization', this.token)

    return this.http.get('/api/no_facturadas_textilera/' + fecha_inicio_encode + "/" + fecha_fin_encode, { headers: headers })
      .map(res => res.json())
  }
}
