import { Component, OnInit } from '@angular/core';
import { SeguimientoEfectivosService } from '../services/seguimiento_efectivos.service';
import {IMyDrpOptions, IMyDateRangeModel} from 'mydaterangepicker';
import * as moment from 'moment'
import {global_functions} from '../global_functions/global_functions';
import { OneDriveService } from '../services/one_drive.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'



@Component({
  selector: 'app-seguimiento-efectivos',
  templateUrl: './seguimiento-efectivos.component.html',
  styleUrls: ['./seguimiento-efectivos.component.css']
})
export class SeguimientoEfectivosComponent implements OnInit {

  private loader: boolean = true
  private dataArrayOracle: any 
  private dataArrayPOS: any
  private finalArray = []; 
  private sendEmailArray = []; 
  private eventStart = ''
  private eventEnd = ''
  private unidadesArray = [{name:'CN_VIA'},{name:'CN_VCAT'},{name:'CN_TEX'},{name:'CN_GTM'},{name:'CN_CAN'}]
  private unidadSelected = ''


  private moduleSeguimientoEfectivoPermission = false
  private globalObj = global_functions;

  
  private endDate = moment().format('DD/MM/YYYY')  
  private startDate = moment().subtract(7, 'day').format('DD/MM/YYYY');

  // private startDate = moment().subtract(7, 'day').format('DD/MM/YYYY');

  
  
  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
  };

  private model: any = {beginDate: this.startDate,
                        endDate: this.endDate};

  constructor(private SeguimientoEfectivosService: SeguimientoEfectivosService,
    private oneDriveService: OneDriveService) { }

  ngOnInit() {
    this.moduleSeguimientoEfectivoPermission = this.globalObj.validateModulePermission(localStorage.getItem('permission_seguimiento'))

    if(this.moduleSeguimientoEfectivoPermission){
      this.unidadSelected = this.unidadesArray[0].name
      this.getData(this.startDate,this.endDate, this.unidadSelected)
    }else{
      this.globalObj.alertAccesDenied()
    }
  } 

  /**
   * Get data from Oracle and POS
   * @param fechaInicio 
   * @param fechaFin 
   */
  private getData(fechaInicio,fechaFin, unidad) {
    this.loader = true
    this.eventStart = fechaInicio
    this.eventEnd = fechaFin
    


    this.SeguimientoEfectivosService.GetSeguimientoEfectivosAyerGeneral(btoa(fechaInicio),btoa(fechaFin),unidad).subscribe(data => {
      this.finalArray = data
      this.loader = false
      console.log("correct ->",this.finalArray)
    }, err => {
      console.log("Error -> "+err)
      this.finalArray = null
      this.loader = false
    })
  }

  /**
   * It´s called when a date is selected
   * @param event 
   */
  onDateRangeChanged(event: IMyDateRangeModel) {
    // event properties are: event.beginDate, event.endDate, event.formatted,
    // event.beginEpoc and event.endEpoc
    
    this.eventStart = this.completeDate(event.beginDate.day) +"/"+ this.completeDate( event.beginDate.month)+"/"+ event.beginDate.year

    this.eventEnd = this.completeDate(event.endDate.day) +"/"+ this.completeDate(event.endDate.month)+"/"+ event.endDate.year
  
    this.sendEmailArray =[]

    this.getData(this.eventStart,this.eventEnd,this.unidadSelected)
  }

  /**
   * 
   * @param day Return 2 digits 
   */
  private completeDate(day){
    let endDay = ''
    switch (day) {
      case 1:
        endDay = '01'
          break;
      case 2:
      endDay = '02'
          break;
      case 3:
      endDay = '03'
          break;
      case 4:
      endDay = '04'
          break;
      case 5:
      endDay = '05'
          break;
      case 6:
      endDay = '06'
          break;
      case 7:
      endDay = '07'
          break;
      case 8:
      endDay = '08'
          break;
      case 9:
      endDay = '09'
          break;
      default:
      endDay=""+day
    }
    return endDay
  }


  /**
   * Join data from Oracle and POS
   */
  private joinArrays(){

    var tempfinalArray = []; 

    for(var i = 0; i < this.dataArrayOracle.length;i++){
      tempfinalArray.push({
            PARTY_SITE_NUMBER: this.dataArrayOracle[i].PARTY_SITE_NUMBER,
            RESOURCE_NAME: this.dataArrayOracle[i].RESOURCE_NAME,
            REGION: this.dataArrayOracle[i].REGION,
            ZONA: this.dataArrayOracle[i].ZONA,
            EMAIL_ADDRESS: this.dataArrayOracle[i].EMAIL_ADDRESS,
            ORACLE_TERMINAL:this.dataArrayOracle[i].TERMINAL,
            ORACLE_EFECTIVO:this.dataArrayOracle[i].EFECTIVO,
            ORACLE_SUMA:this.dataArrayOracle[i].SUMA,
            POS_TERMINAL:0,
            POS_EFECTIVO:0,
            POS_SUMA:0,
            COMENTARIO:""
        })
    }

    for (let index = 0; index < this.dataArrayPOS.length; index++) {
      const elementPOS = this.dataArrayPOS[index];
      var objIndex = tempfinalArray.findIndex((obj => obj.PARTY_SITE_NUMBER[0] == elementPOS.SITE_US_ID[0]));
      if(objIndex>-1){
        tempfinalArray[objIndex].POS_TERMINAL += parseFloat(elementPOS.TERMINAL[0])
        tempfinalArray[objIndex].POS_EFECTIVO += parseFloat(elementPOS.EFECTIVO[0])
        tempfinalArray[objIndex].POS_SUMA += parseFloat(elementPOS.SUMA[0])
        tempfinalArray[objIndex].COMENTARIO = this.getComment(tempfinalArray[objIndex].POS_SUMA.toFixed(1),tempfinalArray[objIndex].ORACLE_SUMA)
      }
    }

    this.finalArray = tempfinalArray
    this.loader = false
  }

  /**
   * Return difference between 2 numbers
   * @param a 
   * @param b 
   */
  getDifference (a, b) { return Math.abs(a - b).toFixed(1); }
  
  /**
   * Return  Diferencia or Ok depending difference
   * @param a 
   * @param b 
   */
  private getComment (a: number, b: number) { 
    var difference : any
    difference = Math.abs(a - b).toFixed(1) 
    if(difference>0){
      return 'Diferencia'
    }else{
      return 'Ok'
    }
  }

  /**
   * Download excel
   */
  private downloadData(){
    this.globalObj.prepareDownload("Seguimiento Efectivos",this.finalArray)
  }

   /**
   * It´s called when a checkbox is checked or uncheked
   * @param item 
   */
  private onCheckChange(event,item){
    if(!event.target.checked){//if checkbox checked 
      let index = this.sendEmailArray.findIndex(x => x.PARTY_SITE_NUMBER==item.PARTY_SITE_NUMBER); //get item index in array
      this.sendEmailArray.splice(index,1)//remove object from array 
    }else{
      this.sendEmailArray.push(item) //insert object into array to send email
    }
    // event.target.checked = !event.target.checked // check status is changed 
  }

  /**
   * Send a email for each object in array 
   */
  private sendEmails(){
    let emailTitle = ''
    let emailText = ''
    let incidencia = ''

    Swal({title:'Enviando Correos...',allowOutsideClick: false})
    Swal.showLoading()
    let sent = 0 
    this.sendEmailArray.forEach(element => {

      let temp = element.PARTY_SITE_NUMBER[0]
      let cc = temp.split("-");
      if(cc.length == 1){
        cc = temp.split("_");
      }    

      if(element.ORACLE_TERMINAL == 0 && element.POS_TERMINAL > 0){
        incidencia = 'Faltante del cierre de la terminal'
        emailTitle = cc[1] + ' ' +incidencia
        emailText = `Buen día 
        \nLos días del ${this.eventStart} al ${this.eventEnd} no realizaron el cierre de la terminal, de la manera más atenta les pido de favor realizar el cierre de la terminal inalámbrica al termino de operaciones del CN.
       
        \nSi tienen algún problema favor de realizar el reporte al 01800 del Banco y enviarlo.
       
        \n¡Saludos!`
      }else if(element.ORACLE_EFECTIVO == 0 && element.POS_EFECTIVO > 0){
        incidencia ='Depósito a destiempo'
        emailTitle = cc[1] + ' ' +incidencia
        emailText =  `Buen día 

        \nFavor de realizar el depósito de la venta del los días ${this.eventStart} al ${this.eventEnd} y subir los cortes en One Drive.
        \nRecuerde que los depósitos del fin de semana se tienen que realizar a más tardar a la 1:00 pm del día Lunes y entre semana es al día siguiente de la venta. 
        \nQuedo en espera de los comprobantes.
        
        \nSaludos `
      }else{
        incidencia ='Depósito incompleto'
        emailTitle = cc[1] + ' ' +incidencia
        emailText = `Buen día. 

        \nLos días del ${this.eventStart} al ${this.eventEnd} tienen faltante por ${this.getDifference(element.POS_SUMA.toFixed(1),element.ORACLE_SUMA)} favor de realizar el depósito a la brevedad.
        
        \nQuedo en espera del comprobante.
        
        \nSaludos`
      }

      let emailArray =  [{EMAIL:encodeURIComponent(element.EMAIL_ADDRESS), TITLE: encodeURIComponent(emailTitle), MESSAGE: encodeURIComponent(emailText), 
        PARTY_SITE_NUMBER: element.PARTY_SITE_NUMBER, RESOURCE_NAME:encodeURIComponent(element.RESOURCE_NAME), TIPO_INCIDENCIA: encodeURIComponent(incidencia)  }]
      this.oneDriveService.SendEmail(emailArray,encodeURIComponent(element.REGION), encodeURIComponent(element.ZONA)).subscribe(data => { 
        sent++
        if(sent==this.sendEmailArray.length){
          Swal.close()
          this.globalObj.alertSucces( this.sendEmailArray.length+' Notificaciones enviadas correctamente')
        }
        }, err => {
          console.log("Error-> "+err)
        })
    });
    
  }

}
