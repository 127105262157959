import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../../home/home.component';
import { AuthGuard } from '../../auth.guard';
import { CentrosNegocioComponent } from '../../centros-negocio/centros-negocio.component';
import { SemaforoComponent } from '../../semaforo/semaforo.component';
import { TransaccionesComponent } from '../../transacciones/transacciones.component';
import { FormasPagoComponent } from '../../formas-pago/formas-pago.component';
import { RespaldoComponent } from '../../respaldo/respaldo.component';
import { SeguimientoEfectivosComponent } from '../../seguimiento-efectivos/seguimiento-efectivos.component';
import { BancoVianneyComponent } from '../../banco-vianney/banco-vianney.component';
import { MovimientosSabComponent } from '../../movimientos-sab/movimientos-sab.component';
import { AplicacionesManualesComponent } from '../../aplicaciones-manuales/aplicaciones-manuales.component';
import { AdministradorComponent } from '../../administrador/administrador.component';
import { ActividadesComponent } from '../../actividades/actividades.component';
import { PendientesDepositosComponent } from '../../pendientes-depositos/pendientes-depositos.component';
import { OneDriveComponent } from '../../one-drive/one-drive.component';
import { IncidenciasComponent } from '../../incidencias/incidencias.component';
import { HerramientasComponent } from '../../herramientas/herramientas.component';
import { VentasLineaComponent } from '../../ventas-linea/ventas-linea.component';
import { VentasLineaSsComponent } from '../../ventas-linea-ss/ventas-linea-ss.component';
import { CantiaColaboradorComponent } from '../../cantia-colaborador/cantia-colaborador.component';

export const SECURE_ROUTES: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'centros_negocio', component: CentrosNegocioComponent, canActivate: [AuthGuard] },
    { path: 'semaforo', component: SemaforoComponent, canActivate: [AuthGuard] },
    { path: 'transacciones', component: TransaccionesComponent, canActivate: [AuthGuard] },
    { path: 'formaspago', component: FormasPagoComponent, canActivate: [AuthGuard] },
    { path: 'respaldo', component: RespaldoComponent, canActivate: [AuthGuard] },
    { path: 'seguimiento', component: SeguimientoEfectivosComponent, canActivate: [AuthGuard] },
    { path: 'banco-vianney', component: BancoVianneyComponent, canActivate: [AuthGuard] },
    { path: 'movimientos-sab', component: MovimientosSabComponent, canActivate: [AuthGuard] },
    { path: 'aplicaciones-manuales', component: AplicacionesManualesComponent, canActivate: [AuthGuard] },
    { path: 'administrador', component: AdministradorComponent, canActivate: [AuthGuard] },
    { path: 'actividades', component: ActividadesComponent, canActivate: [AuthGuard] },
    { path: 'one-drive', component: OneDriveComponent, canActivate: [AuthGuard] },
    { path: 'pendientes-depositos', component: PendientesDepositosComponent, canActivate: [AuthGuard] },
    { path: 'incidencias', component: IncidenciasComponent, canActivate: [AuthGuard] },
    { path: 'herramientas', component: HerramientasComponent, canActivate: [AuthGuard] },
    { path: 'ventas-linea', component: VentasLineaComponent, canActivate: [AuthGuard] },
    { path: 'ventas-ss', component: VentasLineaSsComponent, canActivate: [AuthGuard] },
    { path: 'ventas-cantia', component: CantiaColaboradorComponent, canActivate: [AuthGuard] },
]; 